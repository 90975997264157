import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../components/Spinner";
import OwnedChallengeCard from "./OwnedChallengeCard";
import NotOwnedChallengeCard from "./NotOwnedChallengeCard";
import ChallengeDetailsModal from "./ChallengeDetailsModal";

import {GET_CHALLENGES} from "../../redux/actions/challenge-actions";
import Achievements from "./achievements";
import {useLocation} from "react-router-dom";
import {RESET_ACHIEVEMENTS_DATA} from "../../redux/actions/achievement-actions";
import {SET_PLAY_TAB} from "../../redux/actions/common-actions";
import {usePrevious} from "../../hooks/usePrevious";
import {useAuth} from "../../context/AuthContext";


const Challenge = ({
                       ownedChallenges,
                       notOwnedChallenges,
                       newUserAchievementsCount,
                       loadingChallenges,
                       getChallenges,
                       challengesNeedUpdate,
                       resetAchievementsData,
                       setTab,
                       tab,
                       showDetailsDialog
}) => {

    const { currentUser } = useAuth()
    const location = useLocation();
    const prevLoading = usePrevious(loadingChallenges)

    const [firstLoadingComplete, setFirstLoadingComplete] = useState(false)

    const handleSelectedTab = (selectedTab) => {
        if (selectedTab !== tab) {
            if (selectedTab !== 'achievements') {
                resetAchievementsData();
            }
            setTab(selectedTab);
        }
    }

    useEffect(()=>{
        if(currentUser !== null && !challengesNeedUpdate && (loadingChallenges === false && prevLoading === undefined)){
            getChallenges();
        }
    }, [loadingChallenges, prevLoading, challengesNeedUpdate, currentUser])

    useEffect(() => {
        if(!challengesNeedUpdate && (loadingChallenges === false && prevLoading === true)){
            setFirstLoadingComplete(true)
        }
    }, [challengesNeedUpdate, loadingChallenges, prevLoading]);

    useEffect(()=>{
        if(location.state?.from !== '/homework'){
            setTab(ownedChallenges.length > 0 ? 'owned' : 'not_owned')
        } else if (location.state?.from === '/homework' && location.state?.code === 'newAchievement') {
            setTab('achievements')
        }
    }, [location.state?.code, location.state?.from, ownedChallenges])

    useEffect(()=>{
        if(challengesNeedUpdate){
            getChallenges();
        }
    }, [challengesNeedUpdate])

    const OwnedChallengesContent =() => {
        return (
            <div className={"pt-4 flex flex-col"}>
                {ownedChallenges.map((challenge) => (
                    <div key={challenge.id} className={"py-2"}>
                        <OwnedChallengeCard userChallenge={{ ...challenge }} />
                    </div>
                ))}
            </div>
        );
    };

    const NotOwnedChallengesContent = () => {
        return (
            <div className={"pt-4 flex flex-col"}>
                {notOwnedChallenges.map((challenge) => (
                    <div key={challenge.id} className={"py-2"}>
                        <NotOwnedChallengeCard challenge={{ ...challenge }} />
                    </div>
                ))}
            </div>
        );
    };

    const AchievementsContent = () => {
        return <Achievements />;
    };

    return (
        <React.Fragment>
            <div className={"flex flex-col px-5 pt-24 overflow-hidden"}>
                {firstLoadingComplete === false ? (
                    <div className={"flex w-full h-screen justify-center items-center"}>
                        <Spinner size="lg"/>
                    </div>

                ) : (
                    <React.Fragment>
                        <div role="tablist" className="tabs tabs-bordered tabs-xs ms:tabs-sm">
                            <button onClick={() => handleSelectedTab('owned')}
                                    className={`tab ${tab === 'owned' ? 'tab-active' : ''}`}>Playing
                            </button>
                            <button onClick={() => handleSelectedTab('not_owned')}
                                    className={`tab ${tab === 'not_owned' ? 'tab-active' : ''}`}
                            >
                                Not played
                                {notOwnedChallenges.length > 0 && <div className="ml-1 badge badge-success text-white">{notOwnedChallenges.length}</div>}
                            </button>
                            <button
                                onClick={() => handleSelectedTab('achievements')}
                                className={`tab ${ tab === 'achievements' ? 'tab-active' : '' }`}
                            >
                                Badges
                                {newUserAchievementsCount > 0 && <div className="ml-1 badge badge-success badge-xs ms:badge-sm text-white">{newUserAchievementsCount}</div>}
                            </button>
                        </div>
                        {{
                            owned: <OwnedChallengesContent />,
                            not_owned: <NotOwnedChallengesContent />,
                            achievements: <AchievementsContent />
                        }[tab]}
                    </React.Fragment>
                )}
                {showDetailsDialog && <ChallengeDetailsModal />}
            </div>
        </React.Fragment>
    )
}

Challenge.propTypes = {
    userInfo: PropTypes.object,
    ownedChallenges: PropTypes.array,
    notOwnedChallenges: PropTypes.array,
    newUserAchievementsCount: PropTypes.number,
    loadingChallenges: PropTypes.bool,
    challengesNeedUpdate: PropTypes.bool,
    getChallenges: PropTypes.func,
    resetAchievementsData: PropTypes.func,
    setTab: PropTypes.func,
    tab: PropTypes.string,
    loadingUserInfo: PropTypes.bool,
    showDetailsDialog: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    loadingUserInfo: state.user.loadingUserInfo,
    ownedChallenges: state.challenge.ownedChallenges,
    notOwnedChallenges: state.challenge.notOwnedChallenges,
    newUserAchievementsCount: state.achievement.newUserAchievementsCount,
    loadingChallenges: state.challenge.loadingChallenges,
    challengesNeedUpdate: state.challenge.challengesNeedUpdate,
    tab: state.common.playTab,
    showDetailsDialog: state.challenge.showDetailsDialog,
})

const mapDispatchToProps = (dispatch) => ({
    getChallenges: () => dispatch({ type: GET_CHALLENGES }),
    resetAchievementsData: () => dispatch({ type: RESET_ACHIEVEMENTS_DATA }),
    setTab: (tab) => dispatch({ type: SET_PLAY_TAB, payload: tab })
})

export default connect(mapStateToProps, mapDispatchToProps)(Challenge)

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_PAGE_BACKGROUND } from '../redux/actions/common-actions';

const usePageBackground = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const pathname = location.pathname;
        if (pathname === '/welcome' || pathname === '/') {
            dispatch({ type: SET_PAGE_BACKGROUND, payload: '#ffffff' });
        } else if (!pathname.startsWith('/homework')) {
            dispatch({ type: SET_PAGE_BACKGROUND, payload: '#fff7ef' });
        }
    }, [location.pathname, dispatch]);
};

export default usePageBackground;
import {
    GET_STORIES_BY_COHORTS_ERROR,
    GET_STORIES_BY_COHORTS_REQUEST,
    GET_STORIES_BY_COHORTS_SUCCESS,
    HIDE_STORIES, RESET_STORIES,
    SHOW_STORIES,
} from "../actions/stories-actions";

import {toast} from "react-toastify";
import ToastMessage from "../../screens/components/toast";
import React from "react";

const initialState = {
    storiesIsOpen: false,
    loadingSeries: false,
    currentStoryIndex: 0,
    series: [],
    storiesIsPaused: true
}

export default function storiesReducer(state = initialState, {type, payload}) {

    switch(type) {

        case HIDE_STORIES: {
            return {
                ...state,
                storiesIsOpen: false,
                storiesIsPaused: true
            }
        }

        case SHOW_STORIES: {
            return {
                ...state,
                storiesIsOpen: true,
                storiesIsPaused: false
            }
        }

        case GET_STORIES_BY_COHORTS_REQUEST: {
            return {
                ...state,
                loadingSeries: true
            };
        }

        case GET_STORIES_BY_COHORTS_SUCCESS: {
            return {
                ...state,
                loadingSeries: false,
                series: payload.results,
            };
        }

        case GET_STORIES_BY_COHORTS_ERROR: {
            payload !== 403 && toast.error(<ToastMessage text={"Can`t get stories"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingSeries: false,
                series: []
            };
        }

        case RESET_STORIES: {
            return {
                ...state,
                storiesIsOpen: initialState.storiesIsOpen,
                loadingSeries: initialState.loadingSeries,
                currentStoryIndex: initialState.currentStoryIndex,
                series: initialState.series,
                storiesIsPaused: initialState.storiesIsPaused
            }
        }

        default:
            return state
    }
}

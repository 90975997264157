import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Achievement from "./achievement";
import { SET_NEED_UPDATE_ACHIEVEMENTS } from "../../../redux/actions/achievement-actions";

const Collection = ({
                        achievement,
                        setSelectedAchievement,
                        achievementWasShown,
                        needUpdateAchievements,
                        setNeedUpdateAchievements
}) => {

    const [count, setCount] = useState(0)
    const [collection, setCollection] = useState(null)

    useEffect(()=>{
        if(achievement && collection === null){
            setCount(achievement.userAchievement.length)
            setCollection([...achievement.userAchievement])
        }
    }, [achievement, collection])

    useEffect(()=>{
        if(achievementWasShown === achievement.id && needUpdateAchievements){
            const updatedCollection = [...collection]

            updatedCollection.map(element=>{
                element.wasShown = true
                return element
            })

            setCollection(updatedCollection)
            setNeedUpdateAchievements(false)
        }

    }, [achievementWasShown, collection, needUpdateAchievements])

    useEffect(() => {
        return () => {
            setCollection(null)
        }
    }, []);

    return (
        <>
            <div
                className={"stack w-[5.5rem] ms:w-[6rem] cursor-pointer items-start mb-4"}
                onClick={()=>{
                    setSelectedAchievement({
                        achievement: achievement,
                        collection: collection,
                        isCollection: true
                    })
                }}
            >
                {collection !== null && collection.map((element, index) => {
                    if(index === 0) {

                        return (
                            <div
                                key={`user-achievement-item-${element.id}`}
                                className={"w-[5rem] ms:w-[6rem] indicator h-full"}
                            >
                                <span
                                    className={`indicator-item badge ${element.wasShown ? 'text-base text-primary p-1 badge-ghost m-1' : 'text-xs mr-2 mt-1 px-1 pb-0.5 badge-success text-white'}`}>
                                    {element.wasShown ? count : 'new'}
                                </span>
                                <Achievement
                                    index={index}
                                    achievement={element}
                                />
                            </div>
                        )
                    } else if(index < 3) {
                        return (
                            <div
                                key={`user-achievement-item-${element.id}`}
                                className={"w-[5rem] ms:w-[6rem] indicator h-[5rem]"}
                            >
                                <Achievement
                                    key={`user-achievement-item-${element.id}`}
                                    index={index}
                                    achievement={element}
                                />
                            </div>
                        )
                    } else {
                        return null
                    }
                })}
            </div>
        </>
    )
}


Collection.propTypes = {
    achievement: PropTypes.object,
    achievementWasShown: PropTypes.string,
    needUpdateAchievements: PropTypes.bool,
    setSelectedAchievement: PropTypes.func,
    setNeedUpdateAchievements: PropTypes.func
}

const mapStateToProps = (state) => ({
    achievementWasShown: state.achievement.achievementWasShown,
    needUpdateAchievements: state.achievement.needUpdateAchievements
})

const mapDispatchToProps = (dispatch) => ({
    setNeedUpdateAchievements: (state) => dispatch({ type: SET_NEED_UPDATE_ACHIEVEMENTS, payload: state })
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection)
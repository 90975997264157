import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { getCookieConsentValue } from "react-cookie-consent";

// import useUserInfo from '../hooks/useUserInfo';
import usePageBackground from '../hooks/usePageBackground';

import {useAuth} from "../context/AuthContext";
import {stringToBoolean} from "../helpers/validation";

import Login from "../screens/auth/Login";
import Logout from "../screens/auth/Logout";
import VerifyEmail from "../screens/auth/VerifyEmail";
import Holiday from "../screens/Holiday";
import ResetPassword from "../screens/auth/ResetPassword";
import CookieConsentMessage from "./components/CookieConsentMessage";
import Welcome from "./welcome";
import SprintSummary from "./summary/SprintSummary";
import Homework from "./homework";
import Challenges from "./challenges";
import Hello from "./Hello";
import Account from "./account";
import Billing from "./billing";
import StatsScreen from "./stats";
import LoginPage from "./auth/v2";
import Referrals from "./Referrals";
import CookiesPolicy from "./cookies";
import Test from "./components/test/Test";
import Footer from "./components/Footer";
import ThanksModal from "./components/ThanksModal";
import Header from "./components/Header";

import PrivateRoute from "../routers/PrivateRoute";

import {authPaths, currentMonday, isWeekEnd, nextMonday, weekNumber} from "../helpers/constants";

import {GET_SPRINT} from "../redux/actions/configurator-actions";
import {GET_OPTIONS, SET_OPTIONS} from "../redux/actions/preference-actions";
import {SET_NEAREST_ENROLLMENT_SPRINT_DATE, SET_PAGE_BACKGROUND} from "../redux/actions/common-actions";
import GTMProvider from "../context/GTMProvider";

const Main = ({
                  options,
                  getSprint,
                  currentSprint,
                  displayMode,
                  getOptions,
                  footerHeight,
                  setNearestEnrollmentSprintDate,
                  needUpdateSprint
              }) => {
    const { currentUser } = useAuth();
    const location = useLocation();
    const pathname = location.pathname;
    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
    const mainContainerRef = useRef(null);
    const Routers = process.env.REACT_APP_STAGE !== 'local' ? SentryRoutes : Routes;

    const [showHoliday, setShowHoliday] = useState(false);
    const [showHeader, setShowHeader] = useState(false);
    const [showFooter, setShowFooter] = useState(false);

    usePageBackground();

    useEffect(() => {
        if (needUpdateSprint && currentUser !== null) {
            getSprint(weekNumber);
        }
    }, [needUpdateSprint, currentUser, getSprint]);

    useEffect(() => {
        if (getCookieConsentValue("CookieConsent") === undefined && pathname !== '/cookies-policy') {
            toast(<CookieConsentMessage />, { autoClose: false, closeOnClick: false, toastId: "cookieConsent", closeButton: false });
        }
    }, [pathname]);

    useEffect(() => {
        if (options && options.length > 0) {
            const sprintSchedule = options.find(option => option.code === 'sprint_schedule');
            const isHoliday = !stringToBoolean(sprintSchedule.value);
            setShowHoliday(isHoliday);

            if (!isHoliday && ((pathname === '/' || pathname === 'welcome' || pathname === '/sprint'))) {
                getSprint(weekNumber);
            }
        }
    }, [getSprint, options, weekNumber]);

    useEffect(() => {
        if (currentUser != null && pathname !== '/logout') {
            getOptions();
        }
    }, [currentUser, getOptions, pathname]);

    useEffect(() => {
        setShowFooter(pathname === '/' || pathname === '/welcome');
        setShowHeader(pathname === '/' || pathname === '/welcome' || (!authPaths.includes(pathname) && pathname !== "/test"));
    }, [pathname]);

    useEffect(() => {
        if (currentSprint) {
            const isEnrolled = currentSprint.enrolled;

            if (isWeekEnd) {
                setNearestEnrollmentSprintDate(nextMonday?.format('MMMM, D Y'));
            } else {
                if (isEnrolled) {
                    setNearestEnrollmentSprintDate(nextMonday?.format('MMMM, D Y'));
                } else {
                    setNearestEnrollmentSprintDate(currentMonday?.format('MMMM, D Y'));
                }
            }
        }
    }, [currentSprint, isWeekEnd]);

    return (
        <GTMProvider>
            <div ref={mainContainerRef} className={"flex flex-col min-h-screen w-full xs:max-w-laptop relative"} style={{ paddingBottom: footerHeight + 20 }}>
                {showHeader && <Header />}
                <Routers>
                    {showHoliday ? (
                        <>
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/logout" element={<Logout />} />
                            <Route exact path="*" element={<Holiday />} />
                        </>
                    ) : (
                        <>
                            {['/', '/welcome'].map((path, index) => (
                                <Route path={path} element={<PrivateRoute><Welcome mainContainerRef={mainContainerRef} /></PrivateRoute>} key={index} />
                            ))}
                            <Route path={'/sprint'} element={<PrivateRoute><SprintSummary /></PrivateRoute>} />
                            <Route exact path="/homework/:homeworkId" element={<PrivateRoute><Homework /></PrivateRoute>} />
                            <Route exact path="/reset/:uid/:token" element={<ResetPassword />} />
                            <Route path="/challenges" element={<PrivateRoute><Challenges /></PrivateRoute>} />
                            <Route path="*" element={<PrivateRoute><Hello /></PrivateRoute>} />
                            <Route exact path="/account" element={<PrivateRoute><Account displayMode={displayMode} /></PrivateRoute>} />
                            <Route exact path="/billing" element={<PrivateRoute><Billing /></PrivateRoute>} />
                            <Route exact path="/stats" element={<PrivateRoute><StatsScreen displayMode={displayMode} /></PrivateRoute>} />
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/v2/login" element={<LoginPage />} />
                            <Route exact path="/logout" element={<Logout />} />
                            <Route exact path={"/verify-email"} element={<VerifyEmail />} />
                            <Route exact path={"/referrals"} element={<Referrals />} />
                            <Route exact path={"/cookies-policy"} element={<CookiesPolicy />} />
                            <Route exact path={"/test"} element={<Test />} />
                        </>
                    )}
                </Routers>
                {showFooter && <Footer />}
                <ThanksModal />
            </div>
        </GTMProvider>
    );
}

Main.propTypes = {
    needUpdateSprint: PropTypes.bool,
    getSprint: PropTypes.func,
    weekNumber: PropTypes.string,
    displayMode: PropTypes.string,
    footerHeight: PropTypes.number,
    pageBackground: PropTypes.string,
    currentSprint: PropTypes.object,
    getOptions: PropTypes.func,
    setOptions: PropTypes.func,
    options: PropTypes.array,
    setPageBackground: PropTypes.func,
    setNearestEnrollmentSprintDate: PropTypes.func,
    currentMonday: PropTypes.any,
    nextMonday: PropTypes.any,
    isWeekEnd: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    pageBackground: state.common.pageBackground,
    currentSprint: state.config.currentSprint,
    isWeekEnd: state.common.isWeekEnd,
    needUpdateSprint: state.config.needUpdateSprint,
    options: state.preference.options,
    footerHeight: state.common.footerHeight,
})

const mapDispatchToProps = (dispatch) => ({
    getSprint: (weekNumber) => dispatch({ type: GET_SPRINT, payload: weekNumber }),
    getOptions: (params) => dispatch({ type: GET_OPTIONS, payload: params }),
    setOptions: (options) => dispatch({ type: SET_OPTIONS, payload: options }),
    setPageBackground: (background) => dispatch({ type: SET_PAGE_BACKGROUND, payload: background }),
    setNearestEnrollmentSprintDate: (date) => dispatch({ type: SET_NEAREST_ENROLLMENT_SPRINT_DATE, payload: date })
})

export default connect(mapStateToProps, mapDispatchToProps)(Main);
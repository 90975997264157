import {
    SET_LOADING,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
    SET_HOMEWORK_ID,
    SET_FTF,
    SET_FTF_PAIR,
    SET_EMPTY_FTF,
    CREATE_STUDENT_ANSWERS_REQUEST,
    CREATE_STUDENT_ANSWERS_SUCCESS,
    CREATE_STUDENT_ANSWERS_ERROR,
    GET_STUDENT_HOMEWORK_SUCCESS,
    GET_STUDENT_HOMEWORK_ERROR,
    CLEAN_HOMEWORK_DATA,
    GET_SPRINT_REQUEST,
    GET_SPRINT_SUCCESS,
    GET_SPRINT_ERROR,
    GET_SPRINT_SUMMARY_REQUEST,
    GET_SPRINT_SUMMARY_SUCCESS,
    GET_SPRINT_SUMMARY_ERROR,
    GET_STUDENT_HOMEWORK_REQUEST,
    GET_SPRINTS_SUMMARIES_REQUEST,
    GET_SPRINTS_SUMMARIES_SUCCESS,
    GET_SPRINTS_SUMMARIES_ERROR,
    NEED_UPDATE_SPRINT,
} from '../actions/configurator-actions'

import { toast } from "react-toastify";
import ToastMessage from "../../screens/components/toast";
import React from "react";

const initialState = {
    sprintsSummaries: null,
    sprintsSummariesLoading: false,
    loadingGetHomework: false,
    currentSprint: null,
    currentSprintLoading: false,
    sprintSummary: null,
    sprintSummaryLoading: false,
    getHomeworkError: null,
    loadingCreateStudentAnswer: false,
    loadingDailySummary: false,
    hasAnswers: false,
    loading: false, // use for ftp sagas
    homeworkId: null,
    type: '',
    videoLink: '',
    startTime: 0.0,
    endTime: 0.0,
    withMedia: false,
    image: null,
    isImage: false,
    isVideo: false,
    background: { type: '', source: '' },
    assignment: [],
    editorRawData: { blocks: [], entityMap: {} },
    studentAnswers: null,
    ftf: {},
    pair: {},
    quizletId: null,
    quizletType: null,
    quizletLink: null,
    taskTitle: null,
    homeworkPermissions: null,
    needUpdateSprint: false
}

export default function ConfiguratorReducer(state = initialState, {type, payload}) {
    let isVideo;
    let isImage;
    let isQuizlet;
    let taskTitle

    switch(type) {

        case SET_LOADING:
            return {
                ...state,
                loading: payload !== true ? payload : true
            }

        case GET_STUDENT_HOMEWORK_REQUEST:
            return {
                ...state,
                loadingGetHomework: true,
                getHomeworkError: null,
            }

        case GET_STUDENT_HOMEWORK_SUCCESS:
            isVideo = false;
            isImage = false;
            isQuizlet = false;
            taskTitle = null;

            // eslint-disable-next-line no-case-declarations
            const config = payload?.configuration
            // eslint-disable-next-line no-case-declarations
            const answer = payload?.result

            if(config.media !== null && config.media.type === 'video') {
                isVideo = true
            }
            if(config.media !== null && config.media.type === 'image') {
                isImage = true
            }

            if(config.media !== null && config.media.type === 'quizlet') {
                isQuizlet = true
            }

            if(payload.type !== 'quizlet') {
                taskTitle = config.media?.taskTitle !== undefined ? config.media.taskTitle : null
            }

            return {
                ...state,
                studentAnswers: answer ? answer : initialState.studentAnswers,
                homeworkId: config.homework.id,
                type: config.type,
                videoLink: isVideo ? config.media.videoLink : initialState.videoLink,
                quizletId: isQuizlet ? config.media.quizletId : initialState.quizletId,
                quizletType: isQuizlet ? config.media.quizletType : initialState.quizletType,
                quizletLink: isQuizlet ? config.media.quizletLink : initialState.videoLink,
                startTime: isVideo ? config.media.startTimeMin * 60 + config.media.startTimeSec : initialState.startTime,
                endTime: isVideo ? config.media.endTimeMin * 60 + config.media.endTimeSec : initialState.endTime,
                image: isImage ? config.media.image : initialState.image,
                background: config.background,
                assignment: config.assignment,
                editorRawData: config.editorRawData,
                withMedia: isImage || isVideo,
                isImage: isImage,
                isVideo: isVideo,
                isQuizlet: isQuizlet,
                taskTitle: taskTitle,
                getHomeworkError: null,
                loadingGetHomework: false,
                hasAnswers: !!answer,
                homeworkPermissions: payload?.permissions
            }

        case GET_STUDENT_HOMEWORK_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Can't get homework."} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                studentAnswers: initialState.studentAnswers,
                type: initialState.type,
                videoLink: initialState.videoLink,
                startTime: initialState.startTime,
                endTime: initialState.endTime,
                background: initialState.background,
                assignment: initialState.assignment,
                editorRawData: initialState.editorRawData,
                quizletId: initialState.quizletId,
                quizletType: initialState.quizletType,
                quizletLink: initialState.quizletLink,
                loadingGetHomework: false,
                hasAnswers: initialState.hasAnswers,
            }

        case CLEAN_HOMEWORK_DATA:
            return {
                ...state,
                type: initialState.type,
                videoLink: initialState.videoLink,
                startTime: initialState.startTime,
                endTime: initialState.endTime,
                background: initialState.background,
                assignment: initialState.assignment,
                editorRawData: initialState.editorRawData,
                loadingGetHomework: false,
                getHomeworkError: null,
                hasAnswers: initialState.hasAnswers,
                studentAnswers: initialState.studentAnswers,
                quizletId: initialState.quizletId,
                quizletType: initialState.quizletType,
                quizletLink: initialState.quizletLink,
                taskTitle: initialState.taskTitle,
            }

        case CREATE_STUDENT_ANSWERS_REQUEST:
            return {
                ...state,
                loadingCreateStudentAnswer: true,
            }

        case CREATE_STUDENT_ANSWERS_SUCCESS:
            return {
                ...state,
                studentAnswers: payload,
                loadingCreateStudentAnswer: false,
                hasAnswers: true
            }

        case CREATE_STUDENT_ANSWERS_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Can`t save your answer."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingCreateStudentAnswer: false,
                hasAnswers: false
            }

        case SET_HOMEWORK_ID:
            return {
                ...state,
                homeworkId: payload
            }

        case SET_HOMEWORK_CONFIG_ASSIGNMENT:
            return {
                ...state,
                assignment: payload
            }

        case GET_SPRINT_SUMMARY_REQUEST:
            return {
                ...state,
                loadingSprintSummary: true,
            }

        case GET_SPRINT_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingSprintSummary: false,
                sprintSummary: payload
            }

        case GET_SPRINT_SUMMARY_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Can`t get sprint summary."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                loadingSprintSummary: false,
            }

        case SET_FTF:
            return {
                ...state,
                ftf: payload
            }

        case SET_FTF_PAIR:
            return {
                ...state,
                pair: payload
            }

        case SET_EMPTY_FTF:
            return {
                ...state,
                ftf: initialState.ftf
            }

        case GET_SPRINT_REQUEST:
            return {
                ...state,
                currentSprintLoading: true
            }

        case GET_SPRINT_SUCCESS:
            return {
                ...state,
                currentSprint: payload,
                currentSprintLoading: false,
                needUpdateSprint: false
            }

        case GET_SPRINT_ERROR:
            payload.code !== 403 && toast.error(<ToastMessage text={payload.text} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                currentSprint: payload,
                currentSprintLoading: false,
                needUpdateSprint: false
            }

        case NEED_UPDATE_SPRINT:
            return {
                ...state,
                needUpdateSprint: payload,
            };

        case GET_SPRINTS_SUMMARIES_REQUEST:
            return {
                ...state,
                sprintsSummariesLoading: true,
            }

        case GET_SPRINTS_SUMMARIES_SUCCESS:
            return {
                ...state,
                sprintsSummariesLoading: false,
                sprintsSummaries: payload
            }

        case GET_SPRINTS_SUMMARIES_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Can`t get sprint summary."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                sprintsSummariesLoading: false,
            }

        default:
            return state
    }
}

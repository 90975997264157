import React, {Suspense, useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";

import {HIDE_STORIES} from "../../../redux/actions/stories-actions";

import Story from "./Story";
import {JSONToHTML} from "html-to-json-parser";

const StoriesLazy = React.lazy(() => import("react-insta-stories"));

const Stories = ({height, width, series, storiesIsOpen, loop=true}) => {

    const [stories, setStories] = useState([]);

    useEffect(() => {
        const fetchStories = async () => {
            if (series.length > 0) {
                const newStories = [];
                for (const item of series) {
                    const storyPromises = item.stories.map(async (story) => {
                        const htmlValue = await JSONToHTML(story.config, true);
                        const duration = story.settings.duration

                        return {
                            content: (props) => (
                                <Story
                                    content={htmlValue}
                                    settings={story.settings}
                                    height={height}
                                    props={props}
                                />
                            ),
                            duration: duration ? duration : 8000
                        }
                    })
                    const resolvedStories = await Promise.all(storyPromises);
                    newStories.push(...resolvedStories);
                }
                setStories(newStories);
            }
        };

        fetchStories();
    }, [series]);

    return (
        <>
            {stories.length > 0 && storiesIsOpen ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <StoriesLazy
                        loop={loop}
                        preloadCount={1}
                        preventDefault={false}
                        defaultInterval={8000}
                        stories={stories}
                        height={height}
                        width={width}
                        storyContainerStyles={{ borderRadius: 0, overflow: "hidden" }}
                    />
                </Suspense>
            ) : null }
        </>
    );
};

Stories.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    storiesIsPaused: PropTypes.bool,
    currentStoryIndex: PropTypes.number,
    storiesIsOpen: PropTypes.bool,
    series: PropTypes.array,
    loop: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    currentStoryIndex: state.stories.currentStoryIndex,
    storiesIsPaused: state.stories.storiesIsPaused,
    storiesIsOpen: state.stories.storiesIsOpen
})

const mapDispatchToProps = (dispatch) => ({
    hideStories: () => dispatch({ type: HIDE_STORIES }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Stories)
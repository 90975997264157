import React, {useEffect, useState} from "react";
import SpecialOfferCard from "../components/SpecialOfferCard";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Spinner from "../components/Spinner";

const SpecialOffersList = ({ userInfo, loadingUserInfo, userSubscription }) => {

    const [userOffers, setUserOffers] = useState([])
    const [userHasSubscription, setUserHasSubscription] = useState(false)

    useEffect(() => {
        if(userInfo !== null){
            setUserOffers(userInfo.userOffers)
        }
    }, [userInfo]);

    useEffect(() => {
        if(userSubscription){
            setUserHasSubscription(userSubscription.subscription !== null)
        }
    }, [userSubscription]);

    if(!userHasSubscription) {
        return (
            <React.Fragment>
                {loadingUserInfo ? <Spinner /> : (
                    <React.Fragment>
                        <div className="divider">Special offers</div>
                        {userOffers.map(offer => (
                            <div
                                key={offer.id}
                                className={"px-4"}
                            >
                                <SpecialOfferCard
                                    offer={offer}
                                />
                            </div>
                        ))}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    } else return null
}

SpecialOffersList.propTypes = {
    userInfo: PropTypes.object,
    loadingUserInfo: PropTypes.bool,
    userSubscription: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    userSubscription: state.billing.userSubscription,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOffersList)
import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../screens/components/toast";
import {
    CLOSE_CHALLENGE_DETAILS_DIALOG,
    GET_CHALLENGES_ERROR,
    GET_CHALLENGES_REQUEST,
    GET_CHALLENGES_SUCCESS,
    OPEN_CHALLENGE_DETAILS_DIALOG, RESET_SHOWN_CHALLENGE,
    SEND_CHALLENGE_ACTION_ERROR,
    SEND_CHALLENGE_ACTION_REQUEST,
    SEND_CHALLENGE_ACTION_SUCCESS,
    START_CHALLENGE_ERROR,
    START_CHALLENGE_REQUEST,
    START_CHALLENGE_SUCCESS,
    UPDATE_USER_CHALLENGE_ERROR,
    UPDATE_USER_CHALLENGE_REQUEST,
    UPDATE_USER_CHALLENGE_SUCCESS
} from "../actions/challenge-actions";

const initialState = {
    ownedChallenges: [],
    notOwnedChallenges: [],
    loadingChallenges: false,
    challengesNeedUpdate: false,
    loadingStartChallenge: false,
    sendingChallengeAction: false,
    showDetailsDialog: false,
    challenge: null,
    updatingUserChallenge: false,
    isOwnedChallenge: false,
    dialogType: null
};

export default function challengeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case GET_CHALLENGES_REQUEST:
            return {
                ...state,
                loadingChallenges: true
            }

        case GET_CHALLENGES_SUCCESS:
            console.log(`[GET_CHALLENGES_SUCCESS]`);
            return {
                ...state,
                loadingChallenges: false,
                ownedChallenges: payload.ownedChallenges,
                notOwnedChallenges: payload.notOwnedChallenges,
                challenge: state.challenge !== null && state.challengesNeedUpdate ? payload.ownedChallenges.find(challenge => challenge.challenge.id === state.challenge.id) : state.challenge,
                challengesNeedUpdate: false,
                dialogType: state.challenge !== null ? 'owned' : null,
            }

        case GET_CHALLENGES_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get challenges."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingChallenges: false,
                challengesNeedUpdate: false
            }

        case START_CHALLENGE_REQUEST:
            return {
                ...state,
                loadingStartChallenge: true,
                challengesNeedUpdate: false
            }

        case START_CHALLENGE_SUCCESS:
            console.log(`[START_CHALLENGE_SUCCESS REDUCER] -> ${{loadingStartChallenge: state.loadingStartChallenge, challengesNeedUpdate: state.challengesNeedUpdate}}`);
            return {
                ...state,
                loadingStartChallenge: false,
                challengesNeedUpdate: true
            }

        case START_CHALLENGE_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get your challenges."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingStartChallenge: false,
                challengesNeedUpdate: false
            }

        case SEND_CHALLENGE_ACTION_REQUEST:
            return {
                ...state,
                sendingChallengeAction: true,
                challengesNeedUpdate: false
            }

        case SEND_CHALLENGE_ACTION_SUCCESS:
             payload.message !== null && toast.info(<ToastMessage text={payload.message} />, {
                autoClose: false
            })
            return {
                ...state,
                sendingChallengeAction: false,
                challengesNeedUpdate: true
            }

        case SEND_CHALLENGE_ACTION_ERROR:
            payload.code !== 403 && toast.error(<ToastMessage text={payload.message} withSupportButton={true} withTryAgain={true} code={payload.code} />, {
                autoClose: false
            })
            return {
                ...state,
                sendingChallengeAction: false,
                challengesNeedUpdate: false
            }

        case UPDATE_USER_CHALLENGE_REQUEST:
            return  {
                ...state,
                challengesNeedUpdate: false,
                updatingUserChallenge: true
            }

        case UPDATE_USER_CHALLENGE_SUCCESS:
            return  {
                ...state,
                challengesNeedUpdate: true,
                updatingUserChallenge: false
            }

        case UPDATE_USER_CHALLENGE_ERROR:
            payload.code !== 403 && toast.error(<ToastMessage text={payload.message.detail} withSupportButton={true} withTryAgain={true} code={payload.code} />, {
                autoClose: false
            })
            return  {
                ...state,
                challengesNeedUpdate: false,
                updatingUserChallenge: false
            }

        case OPEN_CHALLENGE_DETAILS_DIALOG:
            return {
                ...state,
                dialogType: payload.isOwned ? 'owned' : 'not_owned',
                showDetailsDialog: true,
                challenge: payload.isOwned ?
                    state.ownedChallenges.find(challenge => challenge.id === payload.challengeId) :
                    state.notOwnedChallenges.find(challenge => challenge.id === payload.challengeId),
            }

        case CLOSE_CHALLENGE_DETAILS_DIALOG:
            return {
                ...state,
                showDetailsDialog: false,
                challenge: null,
                dialogType: null
            }

        case RESET_SHOWN_CHALLENGE: {
            return {
                ...state,
                challenge: null,
                dialogType: null,
                showDetailsDialog: false
            }
        }

        default:
            return state;
    }
}

import React from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import StripeElements from "../StripeElements";
import OfferCard from "./OfferCard";

const SpecialOfferCard = ({ offer }) => {

    return (
        <StripeElements>
            <OfferCard offer={offer} />
        </StripeElements>
    )
}

SpecialOfferCard.propTypes = {
    offer: PropTypes.object,
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOfferCard)
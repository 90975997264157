import React, {useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OneTimePayment from "./OneTimePaymentCard";
import usePaymentProcessor from "../../helpers/PaymentProcessor";
import {useStripe} from "@stripe/react-stripe-js";

const StripePayment = ({ userInfo, priceItems }) => {

    const stripe = useStripe();
    const { processStripePayment } = usePaymentProcessor();

    const [weeks, setWeeks] = useState(1)
    const [loading, setLoading] = useState(false)

    const handleStripePayment = async () => {
        await processStripePayment({
                setLoading: setLoading,
                stripe,
                weeks: weeks,
                isNewCustomer: userInfo.isNewCustomer,
                userInfo: userInfo,
                priceItems: priceItems,
                returnUrl: process.env.REACT_APP_BASE_URL + '/billing'
            }
        );
    }

    return (
        <OneTimePayment
            weeks={weeks}
            setWeeks={setWeeks}
            handleGetMoreWeeks={handleStripePayment}
            loading={loading}
        />
    )
}

StripePayment.propTypes = {
    userInfo: PropTypes.object,
    priceItems: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    priceItems: state.preference.priceItems,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(StripePayment)

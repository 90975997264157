import React, {useEffect, useState} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import paymentApi from "../../redux/api/payment-api";
import StripeCardElement from "./StripeCardElement";
import {toast} from "react-toastify";
import ToastMessage from "../components/toast";
import {useGTMDispatch} from "@elgorditosalsero/react-gtm-hook";
import {isProduction, isWeekEnd, today} from "../../helpers/constants";

const CreateActionDescription = ({
                                     payAndSubscribe,
                                     minimalSubscriptionAmount,
                                     subscriptionStartDate,
                                     currency
}) => {

    const formattedSubscriptionStartDate = subscriptionStartDate.format('DD-MM-YYYY')

    return (
        <div className="alert bg-brandYellow shadow-brand-button rounded-none">
            <div className={"items-start"}>
                🚀
                <div>
                    <h3 className="prose font-bold">IMPORTANT!</h3>
                    <div className={"flex flex-col text-xs mt-1"}>
                        {payAndSubscribe ? (
                            <>
                                <span className={"prose text-xs p-0"}>Please note that your card will be charged {minimalSubscriptionAmount} {currency.toUpperCase()}.</span>
                                <span className={"prose text-xs pt-1"}>Your subscription will commence on {formattedSubscriptionStartDate}</span>
                            </>

                        ) : (
                            <>
                                <span className={"prose text-xs p-0"}>Your subscription will commence on {formattedSubscriptionStartDate}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

CreateActionDescription.propTypes = {
    payAndSubscribe: PropTypes.bool,
    minimalSubscriptionAmount: PropTypes.number,
    subscriptionStartDate: PropTypes.object,
    currency: PropTypes.string
}

const CardPaymentMethodForm = ({
                                   userInfo,
                                   userSubscription,
                                   setupIntentSecret,
                                   setShowDialog,
                                   showDialog,
                                   priceItems,
                                   handleCloseDialogAfterCreateSubscription,
                                   setCreatingSubscription,
                                   creatingSubscription,
}) => {

    const stripe = useStripe();
    const elements = useElements();

    const sendDataToGTM = useGTMDispatch()

    const [setupIntent, setSetupIntent] = useState(null);
    const [paymentCardError, setPaymentCardError] = useState(null)
    const [isCompletePaymentCard, setIsCompletePaymentCard] = useState(false)
    const [offerString, setOfferString] = useState('')

    const product = userSubscription?.product
    const plan = userSubscription?.plan
    const currency = plan?.currency
    const amount = plan?.amount

    const balance = userInfo?.balance?.weeks
    const minimalSubscriptionAmount = userInfo?.userPaymentInfo?.minimalSubscriptionAmount


    const isMonday = today.isoWeekday() === 1 // const isMonday = today.isoWeekday() !== 1 // FOR MONDAY TEST
    const subscriptionStartDate = isWeekEnd ? today.clone().add(1, 'weeks') : today.clone().isoWeekday(6)
    const payAndSubscribe = isMonday && balance === 0

    const handleCreatePaymentMethod = async (e) => {
        setCreatingSubscription(true)
        e.preventDefault();

        const cardElement = elements.getElement(CardElement);

        let { error, setupIntent } = await stripe.confirmCardSetup(setupIntentSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    email: userInfo.profile.email
                }
            }
        });

        if(error) {
            setPaymentCardError(error)
            setCreatingSubscription(false)
            return;
        }

        setSetupIntent(setupIntent);
    }

    const applyCreateSubscriptionResult = (response) => {

        // Отправляем данные в GTM о том что подписка была создана
        isProduction && sendDataToGTM({
            user_id: userInfo.uid,
            event: 'ga4event',
            event_category: 'add_payment_info',
            subscription_id: response.data.subscription.id,
            ecommerce: {
                payment_type: 'subscription',
                items: [{
                    item_name: 'English in Stories',
                    price: (priceItems.aud).toString(),
                    week_number: "1",
                    quantity: "1"
                }]
            }
        })

        handleCloseDialogAfterCreateSubscription(response.data.subscription, setupIntent)
    }

    useEffect(()=>{

        if(setupIntent && setupIntent.status === 'succeeded') {

            let request = {
                customerId: userSubscription.customer,
                paymentMethodId: setupIntent.payment_method,
                planId: userSubscription.plan.id,
                subscriptionStartDate: subscriptionStartDate.format('YYYY-MM-DD'),
                metadata: {
                    isNewCustomer: userInfo.isNewCustomer,
                    email: userInfo.profile.email,
                    source: 'eis_app'
                }
            }

            if(!isWeekEnd){

                request.payAndSubscribe = payAndSubscribe

                paymentApi.createStripeSubscriptionSchedule(request).then(response => {
                    applyCreateSubscriptionResult(response)
                }).catch(error => {
                    toast.error(<ToastMessage text={error.message} withTryAgain={true} withSupportButton={true} />, {
                        autoClose: false
                    })
                    setCreatingSubscription(false)
                })
            } else {
                paymentApi.createStripeSubscription(request).then(response => {
                    applyCreateSubscriptionResult(response)
                }).catch(error => {
                    toast.error(<ToastMessage text={error.message} withTryAgain={true} withSupportButton={true} />, {
                        autoClose: false
                    })
                    setCreatingSubscription(false)
                })
            }
        }
    }, [setupIntent])

    useEffect(() => {
        if(priceItems){
            const currencySymbol = priceItems[`audSymbol`]
            setOfferString(`${currencySymbol}${amount} ${currency.toUpperCase()}`)
        }
    }, [amount, currency, priceItems]);

    if (!stripe || !elements) {
        return '';
    }

    return (
        <>
            <dialog className={`flex flex-row justify-center modal modal-bottom ${showDialog ? 'modal-open' : ''}`}>
                <form method={'dialog'} className="shadow-brand-top border-t-2 shadow-brand-button modal-box w-screen h-screen xs:max-w-laptop pt-4 px-4">
                    {/* CLOSE MODAL BUTTON */}
                    <button
                        onClick={() => setShowDialog(false)}
                        className="btn btn-sm btn-circle btn-primary absolute right-4 top-4"
                    >✕</button>

                    {/* MODAL BODY */}
                    <h3 className="prose font-bold text-lg">{product.name}</h3>
                    <p className="prose py-4">{offerString}</p>
                    {balance === 0 && isWeekEnd ? null : (
                        <CreateActionDescription
                            payAndSubscribe={payAndSubscribe}
                            isMonday={isMonday}
                            minimalSubscriptionAmount={minimalSubscriptionAmount}
                            currency={currency}
                            amount={amount}
                            subscriptionStartDate={subscriptionStartDate}
                        />
                    )}
                    <div className={"divider"} />
                    <h3 className="prose font-bold text-lg">Add payment method</h3>

                    <div className={"h-20"}>
                        <StripeCardElement
                            setPaymentCardError={setPaymentCardError}
                            setIsCompletePaymentCard={setIsCompletePaymentCard}
                            paymentCardError={paymentCardError}
                        />
                    </div>

                    {/* MODAL ACTION */}
                    <div className="modal-action mt-2">
                        <button
                            className={`btn rounded-full shadow-brand-button ${!isCompletePaymentCard ? 'btn-disabled btn-secondary' : 'btn-primary'}`}
                            onClick={handleCreatePaymentMethod}
                        >
                            {creatingSubscription && <span className="loading loading-spinner"></span>}
                            {payAndSubscribe ? 'Create subscription and pay' : 'Create subscription'}
                        </button>
                    </div>
                </form>
            </dialog>
        </>
    )
}

CardPaymentMethodForm.propTypes = {
    setupIntentSecret: PropTypes.string,
    setShowDialog: PropTypes.func,
    showDialog: PropTypes.bool,
    userInfo: PropTypes.object,
    loadingUserInfo: PropTypes.bool,
    userSubscription: PropTypes.object,
    priceItems: PropTypes.object,
    setSubscription: PropTypes.func,
    setNeedUpdateBalanceHistory: PropTypes.func,
    setNeedUpdateUserInfo: PropTypes.func,
    getPaymentMethod: PropTypes.func,
    today: PropTypes.func,
    isWeekEnd: PropTypes.bool,
    handleCloseDialogAfterCreateSubscription: PropTypes.func,
    setCreatingSubscription: PropTypes.func,
    creatingSubscription: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    loadingUserInfo: state.user.loadingUserInfo,
    userSubscription: state.billing.userSubscription,
    priceItems: state.preference.priceItems
})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentMethodForm)

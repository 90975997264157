import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OneTimePayment from "./OneTimePaymentCard";
import {SET_NEED_UPDATE_BALANCE_HISTORY} from "../../redux/actions/billing-actions";

import {useAuth} from "../../context/AuthContext";

import usePaymentProcessor from "../../helpers/PaymentProcessor";
import {cloudpaymentsPublicKey} from "../../helpers/constants";

const CloudPaymentsPayment = ({
                                  userInfo,
                                  setNeedUpdateBalanceHistory,
                                  priceItems,
}) => {

    const { currentUser } = useAuth()

    const { processCloudPaymentsPayment } = usePaymentProcessor();

    const canPaid = userInfo?.isAccountSynced

    const [weeks, setWeeks] = useState(1)
    const [loading, setLoading] = useState(false)
    const [price, setPrice] = useState(0)

    useEffect(() => {
        if(priceItems && userInfo){
            setPrice(priceItems[`${userInfo.profile.currency}`].toFixed(2))
        }
    }, [priceItems, userInfo, userInfo.profile.currency]);

    if(!canPaid) {
        return <Navigate to={'account'} />
    } else {

        const handleCloudPaymentsPayment = async () => {
            await processCloudPaymentsPayment({
                setLoading: setLoading,
                weeks: weeks,
                isNewCustomer: userInfo?.isNewCustomer,
                userInfo: userInfo,
                priceItems: priceItems,
                currentUser: currentUser,
                setNeedUpdateBalanceHistory: setNeedUpdateBalanceHistory,
                cloudpaymentsPublicKey: cloudpaymentsPublicKey,
                price: price
            });
        };

        return (
            <div className={"px-4 pt-10 pb-5"}>
                <OneTimePayment
                    weeks={weeks}
                    setWeeks={setWeeks}
                    handleGetMoreWeeks={handleCloudPaymentsPayment}
                    loading={loading}
                />
            </div>
        )
    }


}

CloudPaymentsPayment.propTypes = {
    userInfo: PropTypes.object,
    priceItems: PropTypes.object,
    setNeedUpdateBalanceHistory: PropTypes.func
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    priceItems: state.preference.priceItems
})

const mapDispatchToProps = (dispatch) => ({
    setNeedUpdateBalanceHistory: (status) => dispatch({ type: SET_NEED_UPDATE_BALANCE_HISTORY, payload: status }),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloudPaymentsPayment)

import React, {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {calculatedSum} from "../../helpers/validation";
import {FaBatteryEmpty, FaBatteryFull, FaBatteryHalf, FaBatteryQuarter} from "react-icons/fa";
import pluralize from "pluralize";
import {IconContext} from "react-icons/lib";
import Spinner from "../components/Spinner";
import {stripeCurrencies} from "../../helpers/constants";
import {SET_NEED_UPDATE_BALANCE_HISTORY} from "../../redux/actions/billing-actions";
import {NEED_UPDATE_USER_INFO} from "../../redux/actions/user-actions";

const OneTimePaymentCard = ({
                                userInfo,
                                weeks,
                                loading,
                                setWeeks,
                                priceItems,
                                handleGetMoreWeeks
}) => {

    const currency = userInfo.profile.currency
    const [currencySymbol, setCurrencySymbol] = useState('')

    const [backgroundClass, setBackgroundClass] = useState('bg-primary')
    const [batteryVariant, setBatteryVariant] = useState(null)

    const [actionElementWidth, setActionElementWidth] = useState(0)
    const actionElementRef = useRef(null)

    const [processingType, setProcessingType] = useState('')

    const [isNewCustomer, setIsNewCustomer] = useState(false)

    useEffect(()=>{
        if(actionElementRef.current){
            setActionElementWidth(actionElementRef.current.clientWidth)
        }
    }, [actionElementRef])

    useEffect(() => {
        if(priceItems && userInfo){
            setProcessingType(stripeCurrencies.includes(userInfo?.profile?.currency) ? 'stripe' : 'cloudPayments')
            setCurrencySymbol(priceItems[`${userInfo.profile.currency}Symbol`])
            setIsNewCustomer(userInfo.isNewCustomer)
        }
    }, [priceItems, userInfo]);

    useEffect(() => {
        if(weeks) {
            switch (true) {
                case weeks === 0:
                    setBatteryVariant(<FaBatteryEmpty />)
                    setBackgroundClass('bg-pink')
                    break
                case weeks === 1 || weeks === 2:
                    setBatteryVariant(<FaBatteryQuarter />)
                    setBackgroundClass('bg-orange')
                    break
                case weeks === 3 || weeks === 4:
                    setBatteryVariant(<FaBatteryHalf />)
                    setBackgroundClass('bg-yellow')
                    break
                case weeks >= 5:
                    setBatteryVariant(<FaBatteryFull />)
                    setBackgroundClass('bg-green')
                    break
                default:
                    setBatteryVariant(<FaBatteryEmpty />)
                    setBackgroundClass('bg-pink')
                    break
            }
        }
    }, [weeks]);

    const getAmount = () => {
        if(priceItems) {
            return calculatedSum(
                weeks,
                isNewCustomer,
                currency,
                priceItems
            )
        }

    }

    return (
        <div
            className={`shadow z-40 card min-h-38 ${backgroundClass}`}
        >
            <div className={"flex flex-col space-y-2"}>

                {/* HEADER */}
                <div className={"flex flex-row justify-between items-center px-3 pt-3 pb-1"}>
                    <span className="text-base font-normal">
                        {`${weeks} ${pluralize('week', weeks)}`}
                    </span>
                    <IconContext.Provider value={{className: "fill-dark w-6 h-6"}}>
                        {batteryVariant}
                    </IconContext.Provider>
                </div>

                {/* BODY */}
                <div className={"flex flex-row space-x-4 items-center text-white px-3"}>
                    <div className={"flex flex-row justify-start space-x-5 mb-1"}>
                        {isNewCustomer ? (
                            <>
                                <div className={"prose text-lg line-through text-primary"}>
                                    {currencySymbol}{weeks * priceItems[`${currency}`]}
                                    <span>{currency.toUpperCase()}</span>
                                </div>
                                <div className={"prose text-lg text-error"}>
                                    {currencySymbol}{getAmount()} <span>{currency.toUpperCase()}</span>
                                </div>
                            </>
                        ) : (
                            <div className={"prose text-lg text-primary"}>
                                {currencySymbol}{getAmount()} <span>{currency.toUpperCase()}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`flex flex-row justify-between items-center pl-3 w-full ${loading ? 'animation-pulse' : ''} 
                    ${processingType !== '' ? 'opacity-90' : ''}`}>
                    <div className={"flex-1"}>
                        <input
                            type="range"
                            min={"1"}
                            max={"8"}
                            value={weeks}
                            onChange={(e) => {
                                setWeeks(Number(e.target.value)
                                )
                            }}
                            className={"range range-md"}
                            step={"1"}
                        />
                    </div>

                    <div
                        style={loading ? {width: actionElementWidth} : {}}
                        ref={actionElementRef}
                        className={`p-0 bg-dark items-center justify-center flex flex-row rounded-none 
                        rounded-br-[2.5rem] rounded-tl-[100px] w-24 ${processingType !== '' ? 'cursor-pointer' : ''}`}
                    >
                        {loading ? (
                            <span className={"pb-2.5 pt-2.5 pl-2.5 text-white"}>
                                <Spinner/>
                            </span>
                        ) : (
                            <div
                                className={"pb-2.5 pt-2.5 pl-2.5 text-white"}
                                onClick={handleGetMoreWeeks}
                            >
                                GET
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

OneTimePaymentCard.propTypes = {
    loading: PropTypes.bool,
    weeks: PropTypes.number,
    setWeeks: PropTypes.func,
    handleGetMoreWeeks: PropTypes.func,
    userInfo: PropTypes.object,
    priceItems: PropTypes.object,
    setNeedUpdateBalanceHistory: PropTypes.func,
    setNeedUpdateUserInfo: PropTypes.func
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    priceItems: state.preference.priceItems
})

const mapDispatchToProps = (dispatch) => ({
    setNeedUpdateBalanceHistory: (status) => dispatch({type: SET_NEED_UPDATE_BALANCE_HISTORY, payload: status}),
    setNeedUpdateUserInfo: (status) => dispatch({ type: NEED_UPDATE_USER_INFO, payload: status })
})

export default connect(mapStateToProps, mapDispatchToProps)(OneTimePaymentCard)

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {stripeCurrencies} from "../../helpers/constants";

import StripeProcessing from "./StripeProcessing";
import Spinner from "../components/Spinner";
import CloudPaymentsPayment from "./CloudPaymentsPayment";
import {SHOW_THANKS_MODAL} from "../../redux/actions/common-actions";
import {usePrevious} from "../../hooks/usePrevious";
import SpecialOffersList from "./SpecialOffersList";

const Billing = ({
                     userInfo,
                     showThanksModal,
                     getSubscriptionLoading,
}) => {

    const [processingType, setProcessingType] = useState('')
    const prevProcessingType = usePrevious(processingType)

    useEffect(() => {
        if(userInfo !== null){
            const vendor = stripeCurrencies.includes(userInfo.profile.currency) ? 'stripe' : 'cloudPayments'
            if(prevProcessingType !== vendor){
                setProcessingType(vendor)
            }
        }
    }, [userInfo]);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success") && (!getSubscriptionLoading && userInfo)) {
            showThanksModal();
        }
    }, []);

    return (
        <React.Fragment>
            { processingType ? (
                <div className={"w-full mb-4 pt-24 space-y-4"}>
                    <React.Fragment>
                        {
                            {
                                stripe: <StripeProcessing/>,
                                cloudPayments: <CloudPaymentsPayment/>
                            }[processingType]
                        }
                    </React.Fragment>
                    <SpecialOffersList />
                </div>
            ) : (
                <div className={"flex flex-row justify-center items-center h-screen"}>
                    <Spinner size="lg"/>
                </div>
            )}
        </React.Fragment>
    )
}

Billing.propTypes = {
    userInfo: PropTypes.object,
    getSubscriptionLoading: PropTypes.bool,
    showThanksModal: PropTypes.func,
    userSubscription: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    getSubscriptionLoading: state.billing.getSubscriptionLoading,
    userSubscription: state.billing.userSubscription,
})

const mapDispatchToProps = (dispatch) => ({
    showThanksModal: (message) => dispatch({ type: SHOW_THANKS_MODAL, payload: message })
})

export default connect(mapStateToProps, mapDispatchToProps)(Billing)

import challengeApi from "../api/challenge-api";

import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_CHALLENGES,
    GET_CHALLENGES_ERROR,
    GET_CHALLENGES_REQUEST,
    GET_CHALLENGES_SUCCESS,
    SEND_CHALLENGE_ACTION,
    SEND_CHALLENGE_ACTION_ERROR,
    SEND_CHALLENGE_ACTION_REQUEST,
    SEND_CHALLENGE_ACTION_SUCCESS,
    START_CHALLENGE,
    START_CHALLENGE_ERROR,
    START_CHALLENGE_REQUEST,
    START_CHALLENGE_SUCCESS,
    UPDATE_USER_CHALLENGE,
    UPDATE_USER_CHALLENGE_ERROR,
    UPDATE_USER_CHALLENGE_REQUEST,
    UPDATE_USER_CHALLENGE_SUCCESS
} from "../actions/challenge-actions";
import {SET_NEW_USER_ACHIEVEMENTS_COUNT} from "../actions/achievement-actions";
import {NEED_UPDATE_USER_INFO} from "../actions/user-actions";

function* getChallenges() {

    console.log('[getChallenges saga] -> send post request');

    yield put({ type: GET_CHALLENGES_REQUEST })

    const response = yield call(challengeApi.getChallenges)

    if(response.status === 200) {
        yield put({ type: GET_CHALLENGES_SUCCESS, payload: response.data })
        yield put({ type: SET_NEW_USER_ACHIEVEMENTS_COUNT, payload: response.data.newUserAchievementsCount })
    } else if (response.status === 404) {
        yield put({ type: GET_CHALLENGES_SUCCESS, payload: null })
    } else {
        yield put({ type: GET_CHALLENGES_ERROR, payload: response.status })
    }
}

function* startChallenge({ payload }) {

    console.log('[startChallenge saga] -> send post request');
    yield put({ type: START_CHALLENGE_REQUEST })

    const response = yield call(challengeApi.startChallenge, payload)

    if(response.status === 200) {
        console.log('[startChallenge saga -> response 200 START_CHALLENGE_SUCCESS]');
        yield put({ type: START_CHALLENGE_SUCCESS, payload: response.data })
        console.log('[startChallenge saga -> response 200 NEED_UPDATE_USER_INFO]');
        yield put({ type: NEED_UPDATE_USER_INFO, payload: true })
    } else {
        yield put({ type: START_CHALLENGE_ERROR, payload: response.status })
    }

}

function* sendChallengeAction({ payload }) {

    yield put({ type: SEND_CHALLENGE_ACTION_REQUEST })

    const response = yield call(challengeApi.sendChallengeAction, payload)

    if(response.status === 200) {
        yield put({ type: SEND_CHALLENGE_ACTION_SUCCESS, payload: response.data })
        if(payload.actionCode === 'REDEEM_OWNER_REWARD' && payload.actionCodeType === 'TOP_UP') {
            yield put({ type: NEED_UPDATE_USER_INFO, payload: true })
        }
    } else {
        yield put({ type: SEND_CHALLENGE_ACTION_ERROR, payload: {message: response.data, code: response.status} })
    }

}

function* updateUserChallenge({ payload }) {

    yield put({ type: UPDATE_USER_CHALLENGE_REQUEST })

    const response = yield call(challengeApi.updateUserChallenge, payload)

    if(response.status === 200) {
        yield put({ type: UPDATE_USER_CHALLENGE_SUCCESS, payload: response.data })
    } else {
        yield put({ type: UPDATE_USER_CHALLENGE_ERROR, payload: {message: response.data, code: response.status} })
    }

}

export default function* challengeSaga() {
    yield takeLatest(GET_CHALLENGES, getChallenges)
    yield takeLatest(START_CHALLENGE, startChallenge)
    yield takeLatest(SEND_CHALLENGE_ACTION, sendChallengeAction)
    yield takeLatest(UPDATE_USER_CHALLENGE, updateUserChallenge)
}

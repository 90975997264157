import {
    put,
    call,
    takeLatest,
    takeEvery
} from 'redux-saga/effects'

import {
    SET_LOADING,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
    SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED,
    SET_HOMEWORK_ID,
    SET_HOMEWORK_ID_REQUESTED,
    CREATE_STUDENT_ANSWERS,
    GET_FTF_PAIR_BY_DATES_REQUESTED,
    SET_FTF,
    SET_FTF_PAIR,
    CREATE_STUDENT_ANSWERS_REQUEST,
    CREATE_STUDENT_ANSWERS_SUCCESS,
    CREATE_STUDENT_ANSWERS_ERROR,
    GET_HOMEWORK,
    GET_STUDENT_HOMEWORK_SUCCESS,
    GET_SPRINT_REQUEST,
    GET_SPRINT_SUCCESS,
    GET_SPRINT_ERROR,
    GET_SPRINT,
    GET_SPRINT_SUMMARY,
    GET_SPRINT_SUMMARY_REQUEST,
    GET_SPRINT_SUMMARY_SUCCESS,
    GET_SPRINT_SUMMARY_ERROR,
    GET_STUDENT_HOMEWORK_REQUEST,
    GET_STUDENT_HOMEWORK_ERROR,
    GET_SPRINTS_SUMMARIES,
    GET_SPRINTS_SUMMARIES_REQUEST,
    GET_SPRINTS_SUMMARIES_SUCCESS,
    GET_SPRINTS_SUMMARIES_ERROR, NEED_UPDATE_SPRINT
} from '../actions/configurator-actions'

import confApi from '../api/configurator-api'
import {NEED_UPDATE_USER_INFO} from "../actions/user-actions";

function* getHomework({ payload }){

    yield put({ type: GET_STUDENT_HOMEWORK_REQUEST })

    let response = yield call(confApi.getStudentAnswerByHomeworkId, payload)

    if (response.status === 200) {
        yield put({ type: GET_STUDENT_HOMEWORK_SUCCESS, payload: response.data })
    }
    else {
        yield put({ type: GET_STUDENT_HOMEWORK_ERROR, payload: response.status })
    }
}

function* setHomeworkId({ payload }) {
    yield put({ type: SET_HOMEWORK_ID, payload: payload })
}

function* createStudentAnswer({ payload }) {

    yield put({ type: CREATE_STUDENT_ANSWERS_REQUEST })

    const response = yield call(confApi.createAnswer, payload)

    if(response && response.status === 200) {
        yield put({ type: CREATE_STUDENT_ANSWERS_SUCCESS, payload: response.data })
        yield put({ type: NEED_UPDATE_USER_INFO, payload: true })
        yield put({ type: NEED_UPDATE_SPRINT, payload: true })
    } else {
        yield put({ type: CREATE_STUDENT_ANSWERS_ERROR, payload: response.status })
    }
}

function* getSprintSummary({ payload }) {

    yield put({ type: GET_SPRINT_SUMMARY_REQUEST })

    const response = yield call(confApi.getSprintSummary, payload)

    if(response && response.status === 200) {
        yield put({ type: GET_SPRINT_SUMMARY_SUCCESS, payload: response.data} )
    } else {
        yield put({ type: GET_SPRINT_SUMMARY_ERROR, payload: response.status })
    }
}

function* getSprintsSummaries({ payload }) {

    yield put({ type: GET_SPRINTS_SUMMARIES_REQUEST })

    const response = yield call(confApi.getSprintsSummaries, payload)

    if(response && response.status === 200) {
        yield put({ type: GET_SPRINTS_SUMMARIES_SUCCESS, payload: response.data} )
    } else {
        yield put({ type: GET_SPRINTS_SUMMARIES_ERROR, payload: response.status })
    }
}

function* setHomeworkConfigAssignment({ payload }) {
    yield put({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: payload })
}

function* getSprint({ payload }) {

    yield put({ type: GET_SPRINT_REQUEST })

    const response = yield call(confApi.getSprint, payload)

    if(response?.status === 200) {
        yield put({type: GET_SPRINT_SUCCESS, payload: response.data })
    } else if (response.status === 404) {
        yield put({type: GET_SPRINT_SUCCESS, payload: null })
    } else {
        yield put({ type: GET_SPRINT_ERROR, payload: {
                text:'Can not get sprint info.',
                code: response.status
            }})
    }
}

function* getFtfPairByDateRange({ payload }){

    yield put({ type: SET_LOADING, payload: true })

    const ftfResponse = yield call(confApi.getFtfByDates, payload)

    if (ftfResponse.status === 200) {
        const ftf = ftfResponse.data[0]
        if(ftf === undefined) {
            yield put({ type: SET_FTF, payload: {} })
        } else {
            yield put({ type: SET_FTF, payload: ftf })

            const params = {
                sprint: ftf.sprint.id,
                student: payload.instagram
            }

            const pairResponse = yield call(confApi.getFtfPairByFtfAndStudent, params)

            if (pairResponse.status === 200) {
                yield put({ type: SET_FTF_PAIR, payload: pairResponse.data[0] })
            }
            if(pairResponse.status >=400 && pairResponse.status < 600){
                // yield put({ type: SET_SPRINT_STUDENTS, payload: [] })
            }
        }
    }
    if(ftfResponse.status >=400 && ftfResponse.status < 600){
        // yield put({ type: SET_SPRINT_STUDENTS, payload: [] })
    }

    yield put({ type: SET_LOADING, payload: false })
}

export default function* configSaga() {
    yield takeLatest(GET_HOMEWORK, getHomework)
    yield takeLatest(GET_SPRINT, getSprint)
    yield takeEvery(CREATE_STUDENT_ANSWERS, createStudentAnswer)
    yield takeEvery(GET_SPRINT_SUMMARY, getSprintSummary)
    yield takeLatest(GET_SPRINTS_SUMMARIES, getSprintsSummaries)
    yield takeLatest(SET_HOMEWORK_ID_REQUESTED, setHomeworkId)
    yield takeLatest(SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED, setHomeworkConfigAssignment)
    yield takeLatest(GET_FTF_PAIR_BY_DATES_REQUESTED, getFtfPairByDateRange)
}

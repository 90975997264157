import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {GET_USER_STATS, RESET_USER_STATS} from "../../../redux/actions/user-actions";
import PropTypes from "prop-types";
import {getSummaryDays} from "../../../helpers/utils";
import Rating from "../../components/Rating";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import {usePrevious} from "../../../hooks/usePrevious";

// eslint-disable-next-line no-unused-vars
const SprintCollapsed = ({ summary, currentCollapseId, setCurrentCollapseId, elementRef, headerElementRect}) => {

    const [days, setDays] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const prevIsOpen = usePrevious(isOpen)

    const sprintStartDate = moment(summary.sprint?.startDate).format('DD-MM-YYYY')
    const sprintEndDate = moment(summary.sprint?.endDate).format('DD-MM-YYYY')

    function handleCollapseOpen(){
        if(isOpen === true) {
            setCurrentCollapseId(null)
        } else {
            setCurrentCollapseId(summary.id)
        }

    }

    useEffect(()=>{
        if(summary !== null) {
            setDays(getSummaryDays(summary.days))
        }
    }, [summary])

    useEffect(()=>{
        if(summary.id !== currentCollapseId){
            setIsOpen(false)
        } else {
            if(isOpen === true){
                setIsOpen(false)
                setCurrentCollapseId(null)
            } else {
                setIsOpen(true)
            }
        }

    }, [currentCollapseId])

    useEffect(() => {
        if(!prevIsOpen && isOpen){
            const scrollPosition = elementRef.current.getBoundingClientRect().top + window.pageYOffset - headerElementRect.height - 10;
            window.scrollTo({
                behavior: 'smooth',
                top: scrollPosition
            });
        }
    }, [isOpen, prevIsOpen, elementRef]);

    return (
        <React.Fragment>
            <div
                ref={elementRef}
                className={`collapse collapse-arrow bg-white shadow-brand-button rounded-none ${isOpen ? 'collapse-open' : 'collapse-close'}`}>
                <div
                    className="collapse-title cursor-pointer"
                    onClick={handleCollapseOpen}
                >
                    <div className={"flex flex-col justify-between items-start"}>
                        <span className={"font-semibold text-base"}>{summary.sprint.title}</span>
                        <span className={"text-xs"}>from {sprintStartDate} to {sprintEndDate}</span>
                    </div>
                </div>
                <div className="collapse-content">
                    <div className={"flex flex-col divide-y divide-primary divide-opacity-50"}>
                        {days && days.map(day => {
                            return day.homeworks.map(homework=>{
                                const allAssignments = homework.answers ? homework.answers.allAssignments : 0
                                const correctAnswer = homework.answers ? homework.answers?.correctAnswer : 0

                                return (
                                    <div
                                        key={uuidv4()}
                                        className={"flex items-center text-sm justify-between py-4"}
                                    >
                                        <div className={"flex flex-col space-y-1 w-8/12"}>
                                            <a className={"btn-link"} href={homework.link}>{homework.title}</a>
                                            {homework.config.type !== "quizlet" && (
                                                <Rating
                                                    status={correctAnswer + allAssignments === 0 ? 'wait' : 'completed'}
                                                    all={allAssignments}
                                                    correct={correctAnswer}/>
                                            )}
                                        </div>
                                        <span className={"badge badge-outline badge-md"}>
                                            <span className={"text-xs"}>Day {day.day}</span>
                                        </span>
                                    </div>
                                )
                            })
                        })}
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

SprintCollapsed.propTypes = {
    userUid: PropTypes.string,
    summary: PropTypes.object,
    userStats: PropTypes.object,
    resetStats: PropTypes.func,
    getUserStats: PropTypes.func,
    setCurrentCollapseId: PropTypes.func,
    currentCollapseId: PropTypes.string,
    elementRef: PropTypes.any,
    scrollToRef: PropTypes.func,
    headerElementRect: PropTypes.object
}

const mapStateToProps = (state) => ({
    userStats: state.user.userStats,
    headerElementRect: state.common.headerElementRect
})

const mapDispatchToProps = (dispatch) => ({
    getUserStats: (userUid) => dispatch({ type: GET_USER_STATS, payload: userUid }),
    resetStats: () => dispatch({ type: RESET_USER_STATS })
})

export default connect(mapStateToProps, mapDispatchToProps)(SprintCollapsed)

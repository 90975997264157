import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {HIDE_THANKS_MODAL} from "../../redux/actions/common-actions";
import PropTypes from "prop-types";
import {GoCheckCircle} from "react-icons/go";
import {useSearchParams} from "react-router-dom";
import pluralize from "pluralize";

const ThanksModal = ({ hideModal, isOpen }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [thanksModalMessage, setThanksModalMessage] = useState('')

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout

        const success_param = searchParams.get('success');
        const weeks_param = searchParams.get('weeks')

        if (success_param) {

            if(weeks_param){
                setThanksModalMessage(`Your purchase of ${weeks_param} ${pluralize('week', weeks_param)} has been confirmed.\n
                An email confirmation will be sent to you shortly.`);
            } else {
                setThanksModalMessage("Order placed! You will receive an email confirmation.");
            }

        }

    }, [searchParams]);

    function handleHideModal() {

        searchParams.delete('canceled')
        searchParams.delete('success')
        searchParams.delete('weeks')

        setSearchParams(searchParams);
        hideModal()
    }

    return (
        <dialog className={`modal ${isOpen ? 'modal-open' : null}`}>
            <div className="modal-box flex flex-col items-center space-y-3">
                <form method="dialog">
                    <button
                        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                        onClick={handleHideModal}
                    >
                        ✕
                    </button>
                </form>
                <GoCheckCircle className={"fill-green w-28 h-28"}/>
                <h3 className="font-bold text-lg">Great!</h3>
                <p className="py-4 text-center">{thanksModalMessage}</p>
                <div className="modal-action">
                    <form method="dialog">
                        <button
                            className="btn"
                            onClick={handleHideModal}
                        >
                            Close
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

ThanksModal.propTypes = {
    hideModal: PropTypes.func,
    isOpen: PropTypes.bool
}

const mapStateToProps = (state) => ({
    isOpen: state.common.thanksModalIsOpen
})

const mapDispatchToProps = (dispatch) => ({
    hideModal: () => dispatch({ type: HIDE_THANKS_MODAL })
})

export default connect(mapStateToProps, mapDispatchToProps)(ThanksModal)
import {
    LOGOUT,
    REFRESH_TOKEN,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    CLEAN_RESET_PASSWORD_DATA,
    NEED_REFRESH_TOKEN
} from "../actions/auth-actions";

const initialState = {
    accessToken: null,
    loadingResetPassword: false,
    resetPasswordError: null,
    resetPasswordDetail: null,
    needRefreshToken: false
};

export default function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        /* RESET PASSWORD */
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loadingResetPassword: true,
                resetPasswordError: null
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loadingResetPassword: false,
                resetPasswordError: null,
                resetPasswordDetail: payload.detail,
            };

        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordDetail: initialState.resetPasswordDetail,
                loadingResetPassword: false,
                resetPasswordError: null,
            };

        case CLEAN_RESET_PASSWORD_DATA:
            return {
                ...state,
                resetPasswordError: null,
                resetPasswordDetail: null,
                loadingResetPassword: false,
            };

        /* LOGOUT */
        case LOGOUT:
            return {
                ...state,
                accessToken: initialState.accessToken,
            };

        /* REFRESH TOKEN */
        case REFRESH_TOKEN:
            return {
                ...state,
                accessToken: payload,
            };

        case NEED_REFRESH_TOKEN:
            return {
                ...state,
                needRefreshToken: payload,
            };

        default:
            return state;
    }
}

import {initializeApp} from 'firebase/app';
import {getInstallations, getId} from 'firebase/installations'

import {
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
} from "firebase/auth";

import {getMessaging, getToken, isSupported, deleteToken} from "firebase/messaging";
import {isLocalhost} from "../helpers/validation";
import {captureSentryException} from "../helpers/sentry";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const installations = getInstallations(app)

// firebase auth
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const appleProvider = new OAuthProvider('apple.com')
export async function getDeviceId() {
    return await getId(installations)
}
export const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging(app);
        }
        return null;
    } catch (error) {
        return null;
    }
})();

export const getOrRegisterServiceWorker = () => {
    if (
        "serviceWorker" in navigator &&
        typeof window.navigator.serviceWorker !== "undefined"
    ) {

        return window.navigator.serviceWorker
            .getRegistration("/service-worker.js")
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register(
                    "/service-worker.js"
                );
            });
    }
    throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = async () => {
    try {
        const messagingResolve = await messaging;
        if (messagingResolve) {
            if(isLocalhost){
                return Promise.resolve(
                    getToken(messagingResolve, {
                        vapidKey: process.env.REACT_APP_VAPID_KEY,
                    })
                );
            } else {
                return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
                    return Promise.resolve(
                        getToken(messagingResolve, {
                            vapidKey: process.env.REACT_APP_VAPID_KEY,
                            serviceWorkerRegistration: serviceWorkerRegistration,
                        })
                    );
                });
            }
        }
    } catch (error) {
        captureSentryException(error)
    }
};

export async function deleteTokenFromFirebase() {
    // Delete registration token.
    return getToken(await messaging).then(async (currentToken) => {
        return deleteToken(await messaging).then(() => {
            return currentToken
        }).catch((error) => {
            captureSentryException(error)
        });
    }).catch((error) => {
        captureSentryException(error)
    });
}

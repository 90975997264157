import {Elements} from "@stripe/react-stripe-js";
import React from "react";
import {loadStripe} from "@stripe/stripe-js";
import {stripePublicKey} from "../../helpers/constants";
import PropTypes from "prop-types";

const StripeElements = ({children}) => {

    const stripeOptions = {
        locale: 'en'
    }

    const stripe = loadStripe(stripePublicKey)

    return <Elements stripe={stripe} options={stripeOptions}>
        {children}
    </Elements>
}

StripeElements.propTypes = {
    children: PropTypes.object,
}

export default StripeElements
import React, {useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {CopyToClipboard} from "react-copy-to-clipboard";
import {BsClipboard, BsClipboardCheck} from "react-icons/bs";

import OwnedChallengeTitle from "./OwnedChallengeDetailsModal/OwnedChallengeTitle";

import {OPEN_CHALLENGE_DETAILS_DIALOG} from "../../redux/actions/challenge-actions";


const OwnedChallengeCard = React.memo(({ userChallenge, openDetailsDialog }) => {

    const [copied, setCopied] = useState(false)

    const copyEffect = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000)
    }

    function openDetails(){
        openDetailsDialog({
            isOwned: true,
            challengeId: userChallenge.id
        })
    }

    return (
        <React.Fragment>
            <div className="card w-full px-4 bg-white shadow-brand-button rounded-none">
                <div className="card-body px-0 pt-4 pb-0">
                    <OwnedChallengeTitle userChallenge={userChallenge} isModal={false} />
                    <div className={"pt-3"}>{userChallenge.challenge.description}</div>
                </div>
                <div className="card-actions flex justify-between px-0 pt-1">
                    <button
                        onClick={openDetails}
                        className="pl-0 btn btn-active btn-link text-xs"
                    >
                        See details
                    </button>
                    {userChallenge.challenge.hasReferral && (
                        <CopyToClipboard
                            text={`${process.env.REACT_APP_ACCOUNT_API_URL}/referrals/${userChallenge.referral.code}`}
                            onCopy={copyEffect}
                        >

                            <button className={`pl-0 btn btn-active btn-link text-xs ${copied ? 'text-accent font-bold' : 'text-primary'}`}>
                                {copied ? <BsClipboardCheck /> : <BsClipboard />}
                                Copy referral link
                            </button>

                        </CopyToClipboard>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
})

OwnedChallengeCard.displayName = "OwnedChallengeCard";

OwnedChallengeCard.propTypes = {
    userChallenge: PropTypes.object,
    openDetailsDialog: PropTypes.func
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch) => ({
    openDetailsDialog: (params) => dispatch({type: OPEN_CHALLENGE_DETAILS_DIALOG, payload: params})
})

export default connect(mapStateToProps, mapDispatchToProps)(OwnedChallengeCard)

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Loading from "../../components/Loading";
import {useAuth} from "../../../context/AuthContext";

import Collection from "./Collection";
import ShareAchievementModal from "./ShareAchievementModal";
import DetailAchievementsCollection from "./DetailAchievementsCollectionModal";
import UserAchievementItem from "./UserAchievementItem";

import {GET_ACHIEVEMENTS} from "../../../redux/actions/achievement-actions";
import {generateAchievements} from "./helper";

const Achievements = ({ userAchievements, loadingAchievements, getAchievements }) => {

    const { currentUser } = useAuth()
    const [elements, setElements] = useState([])

    const [selectedAchievement, setSelectedAchievement] = useState(null)
    const [selectedBadge, setSelectedBadge] = useState(null)

    useEffect(()=>{
        if (!userAchievements) return;
        const generatedAchievements = generateAchievements([...userAchievements])
        const elementsList = []

        generatedAchievements.forEach(achievement=>{
            if(achievement.isStack){
                elementsList.push(achievement)
            } else {
                achievement.userAchievement.forEach(userAchievement=>{
                    userAchievement.isStack = false
                    userAchievement.achievement = achievement
                    elementsList.push(userAchievement)
                })
            }
        })
        setElements(elementsList)
    },[userAchievements])

    useEffect(() => {
        getAchievements(currentUser?.uid);
    }, [getAchievements, currentUser]);

    const achievements = (elements).map((userAchievement, index) => {
        return (
            userAchievement.isStack ? (
                <Collection
                    key={`collection-${userAchievement.id}`}
                    achievement={userAchievement}
                    isClose={userAchievement.isStack}
                    setSelectedAchievement={setSelectedAchievement}
                />
            ) : (
                <UserAchievementItem
                    key={`user-achievement-item-${userAchievement.achievement.id}-${index}`}
                    achievement={userAchievement.achievement}
                    element={userAchievement}
                    innerIndex={index}
                    setSelectedAchievement={setSelectedAchievement}
                />
            )
        )
    })

    return (
        <React.Fragment>
            {loadingAchievements ? <Loading isFullScreen={true}/> : (
                <div className={"flex flex-row flex-wrap gap-4 pt-8 pb-4"}>
                    {achievements}
                </div>
            )}

            <DetailAchievementsCollection
                achievementDetail={selectedAchievement}
                setSelectedAchievement={setSelectedAchievement}
                setSelectedBadge={setSelectedBadge}
            />
            <ShareAchievementModal
                setSelectedBadge={setSelectedBadge}
                selectedBadge={selectedBadge}
            />
        </React.Fragment>
    );
};

Achievements.propTypes = {
    userAchievements: PropTypes.any,
    loadingAchievements: PropTypes.bool,
    getAchievements: PropTypes.func,
    resetAchievementsData: PropTypes.func
}

const mapStateToProps = (state) => ({
    userAchievements: state.achievement.userAchievements,
    loadingAchievements: state.achievement.loadingAchievements,
})

const mapDispatchToProps = (dispatch) => ({
    getAchievements: (userId) => dispatch({ type: GET_ACHIEVEMENTS, payload: userId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Achievements)
import {
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_ERROR,
    UPDATE_STUDENT_PROFILE_REQUEST,
    UPDATE_STUDENT_PROFILE_SUCCESS,
    UPDATE_STUDENT_PROFILE_ERROR,
    RESET_USER_DATA,
    GET_USER_STATS_REQUEST,
    GET_USER_STATS_SUCCESS,
    GET_USER_STATS_ERROR,
    RESET_USER_STATS,
    NEED_UPDATE_USER_INFO,
    GET_USER_INFO_REQUEST
} from "../actions/user-actions";
import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../screens/components/toast";

const initialState = {
    userStats: null,
    userInfo: null,
    loadingUserInfo: false,
    updateProfileLoading: false,
    userStatsLoading: false,
    needUpdateUserInfo: false
};

export default function userReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case GET_USER_INFO_REQUEST:
            return {
                ...state,
                loadingUserInfo: true,
            }

        case GET_USER_INFO_SUCCESS:
            console.log(payload)
            return {
                ...state,
                userInfo: payload,
                loadingUserInfo: false,
                needUpdateUserInfo: false
            }

        case GET_USER_INFO_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get your profile info."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                userInfo: null,
                loadingUserInfo: false,
                needUpdateUserInfo: false
            }

        case NEED_UPDATE_USER_INFO:
            return {
                ...state,
                needUpdateUserInfo: payload
            }

        /* SET USER PROFILE */
        case UPDATE_STUDENT_PROFILE_REQUEST:
            return {
                ...state,
                updateProfileLoading: true,
            };

        case UPDATE_STUDENT_PROFILE_SUCCESS:
            return {
                ...state,
                userInfo: { ...state.userInfo, profile: payload},
                updateProfileLoading: false,
                needUpdateUserInfo: true
            };

        case UPDATE_STUDENT_PROFILE_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                updateProfileLoading: false,
                needUpdateUserInfo: true
            };

        case RESET_USER_DATA:
            return {
                ...state,
                userInfo: null,
                updateProfileLoading: false
            }

        case GET_USER_STATS_REQUEST:
            return {
                ...state,
                userStatsLoading: true
            }

        case GET_USER_STATS_SUCCESS:
            return {
                ...state,
                userStatsLoading: false,
                userStats: payload
            }

        case GET_USER_STATS_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get the stats."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                userStatsLoading: false,
            }

        case RESET_USER_STATS:
            return {
                ...state,
                userStats: initialState.userStats,
                userStatsLoading: initialState.userStatsLoading
            }

        default:
            return state;
    }
}

import React, {useEffect} from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Error from "../../Error";
import QuizletTask from "./QuizletTask";

import {
    CLEAN_HOMEWORK_DATA,
    GET_HOMEWORK,
} from "../../../redux/actions/configurator-actions";
import {
    SET_PAGE_BACKGROUND
} from "../../../redux/actions/common-actions";

const QuizletHomework = ({
                             getHomeworkError,
                             cleanHomeworkData,
                             setPageBackground,
                             background,
}) => {

    useEffect(()=>{

        return () => {
            cleanHomeworkData()
        };
    }, [])

    useEffect(()=>{
        if(background){
            setPageBackground(background.source)
        }
    }, [background, setPageBackground])

    return (
        <React.Fragment>
            <>
                {getHomeworkError ? (
                    <Error error={getHomeworkError} />
                ) : (
                    <>
                        <div className="relative flex flex-col items-center w-full h-screen pt-8">
                            <div className="w-full overflow-y-scroll flex flex-col h-screen">
                                <QuizletTask />
                            </div>
                        </div>
                    </>
                )}
            </>
        </React.Fragment>
    );
}

QuizletHomework.propTypes = {
    browser: PropTypes.string,
    userInfo: PropTypes.object,
    background: PropTypes.object,
    hasAnswers: PropTypes.bool,
    getHomeworkError: PropTypes.object,
    getHomework: PropTypes.func,
    cleanHomeworkData: PropTypes.func,
    setPageBackground: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    background: state.config.background,
    hasAnswers: state.config.hasAnswers,
    getHomeworkError: state.config.getHomeworkError,
})

const mapDispatchToProps = (dispatch) => ({
    getHomework: (params) => dispatch({ type: GET_HOMEWORK, payload: params }),
    cleanHomeworkData: () => dispatch({ type: CLEAN_HOMEWORK_DATA }),
    setPageBackground: (background) => dispatch({ type: SET_PAGE_BACKGROUND, payload: background }),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizletHomework)
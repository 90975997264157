import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {START_CHALLENGE} from "../../redux/actions/challenge-actions";

const StartChallengeButton = ({
                                  startChallenge,
                                  challengeId,
                                  loadingStartChallenge,
                                  loadingUserInfo,
                                  isLink,
                                  title
}) => {

    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        setLoading(loadingStartChallenge === true || loadingUserInfo === true)
    }, [loadingStartChallenge, loadingUserInfo])

    return (
        <React.Fragment>
            <button
                onClick={() => startChallenge({challengeId: challengeId})}
                className={`btn break-words ${isLink ? 'px-0 btn-active btn-link text-xs' : `rounded-full shadow-brand-button btn-primary btn-sm ms:btn-md`}`}
            >
                {loading && <span className="loading loading-spinner"></span>}
                {title}
            </button>
        </React.Fragment>
    )
}

StartChallengeButton.propTypes = {
    title: PropTypes.string,
    startChallenge: PropTypes.func,
    challengeId: PropTypes.string,
    loadingStartChallenge: PropTypes.bool,
    loadingUserInfo: PropTypes.bool,
    isLink: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    loadingStartChallenge: state.challenge.loadingStartChallenge,
    loadingUserInfo: state.user.loadingUserInfo
})

const mapDispatchToProps = (dispatch) => ({
    startChallenge: (params) => dispatch({ type: START_CHALLENGE, payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(StartChallengeButton)
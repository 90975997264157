import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {GTMProvider} from "@elgorditosalsero/react-gtm-hook";

import Main from "./screens/Main";
// import Notification from "./screens/components/Notification";

import AuthProvider from "./context/AuthContext";
import ToastProvider from "./context/ToastContext";

import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener';

import "./App.css";
import {getDisplayMode} from "./helpers/validation";
import * as Sentry from "@sentry/react";
import {captureSentryException} from "./helpers/sentry";

const App = ({ gtmParams, pageBackground }) => {

    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState(null);
    const [displayMode, setDisplayMode] = useState("");

    const cloudPaymentsScript = document.createElement('script');

    useEffect(()=>{

        cloudPaymentsScript.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
        cloudPaymentsScript.async = true;
        cloudPaymentsScript.onload = function() {
            // setLoading(false)
        };
        document.body.appendChild(cloudPaymentsScript);

        return () => {
            if (cloudPaymentsScript.parentNode === document.body) {
                document.body.removeChild(cloudPaymentsScript);
            }
        }
    },[])


    useEffect(() => {
        setDisplayMode(getDisplayMode())
        const updater = async () => {
            if (process.env.NODE_ENV !== "development") {
                const listener = await new ServiceWorkerUpdateListener();
                setSwListener(listener);

                listener.onupdatewaiting = () => {
                    setUpdateWaiting(true);
                };
                listener.onupdateready = () => {
                    window.location.reload();
                };

                navigator.serviceWorker.getRegistration().then((reg) => {
                    if(reg !== undefined){
                        listener.addRegistration(reg);
                        setRegistration(reg);
                    }
                });

                return () => {
                    listener.removeEventListener();
                };
            }
        };

        updater();

        // Cleanup function
        return () => {
            if (swListener) {
                // Cleanup the listener if it's set
                swListener.removeRegistration(registration);
                // Cleanup any other resources if needed
            }
        };
    }, []);

    useEffect(() => {
        if (updateWaiting) {
            toast.info(<UpdateWaiting />, {
                autoClose: false,
                closeOnClick: false,
                toastId: "update"
            });
        } else {
            toast.dismiss('update');
        }

    }, [updateWaiting]);

    const UpdateWaiting = () => {
        const handleUpdateButton = (event) => {
            event.preventDefault();
            try {
                swListener.skipWaiting(registration.waiting);
                toast.dismiss('update');
            } catch (error) {
                captureSentryException(error)
                toast.dismiss('update');
            }
        };

        return (
            <div className="flex flex-row justify-between items-center w-full xs:max-w-laptop">
                <div>Update waiting!</div>
                <button className="btn btn-md btn-primary ml-2" onClick={handleUpdateButton}>Update</button>
            </div>
        );
    };

    return (
            <GTMProvider state={gtmParams}>
                <ToastProvider>
                    <Router>
                        <AuthProvider>
                            <div className={"flex flex-col items-center overflow-x-hidden overflow-y-scroll"}
                                 style={{background: pageBackground}}>
                                <Main displayMode={displayMode}/>
                            </div>
                            {/*<Notification/>*/}
                        </AuthProvider>
                    </Router>
                </ToastProvider>
            </GTMProvider>
    );
};

App.propTypes = {
    gtmParams: PropTypes.object,
    pageBackground: PropTypes.string
}

const mapStateToProps = (state) => ({
    gtmParams: state.common.gtmData,
    pageBackground: state.common.pageBackground
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App));
import {stringToBoolean} from "./validation";
import moment from "moment-timezone";

export const paymentApiUrl = process.env.REACT_APP_PAYMENT_URL+'/api/v1'
export const homeworkApiUrl = process.env.REACT_APP_HOMEWORK_API_URL
export const accountApiUrl = process.env.REACT_APP_ACCOUNT_API_URL
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLICK_KEY
export const cloudpaymentsPublicKey = process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_KEY

export const authPaths = [
    '/login',
    '/v2/login',
    '/logout',
    '/verify-email',
]

export const stripeCurrencies = ['aud', 'eur', 'usd']

export const isProduction = stringToBoolean(process.env.REACT_APP_TEST) === false

export const tracePropagationTargets = [
    "localhost",
    `^${paymentApiUrl.replace(/\//g, "\\/")}`,
    `^${paymentApiUrl.replace(/\//g, "\\/")}`,
    `^${paymentApiUrl.replace(/\//g, "\\/")}`
];

export const today = moment().tz('Australia/Sydney')
export const currentMonday = moment().tz('Australia/Sydney').isoWeekday(1)
export const nextMonday = moment().tz('Australia/Sydney').isoWeekday(1).add(1, 'weeks')
export const isWeekEnd= moment().tz('Australia/Sydney').isoWeekday() === 6 || moment().tz('Australia/Sydney').isoWeekday() === 7
export const weekNumber = moment().tz('Australia/Sydney').format('YYYY-[W]WW')
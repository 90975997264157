export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const CLEAN_LOGIN_DATA = "CLEAN_LOGIN_DATA";
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
export const CLEAN_RESET_PASSWORD_DATA = "CLEAN_RESET_PASSWORD_DATA"
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_REQUESTED = "REFRESH_TOKEN_REQUESTED";
export const NEED_REFRESH_TOKEN = "NEED_REFRESH_TOKEN"
import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import Grid from "./grid";
import {SHOW_THANKS_MODAL} from "../../redux/actions/common-actions";
import Cookies from "js-cookie";
import {GET_STORIES_BY_COHORTS, RESET_STORIES, SHOW_STORIES} from "../../redux/actions/stories-actions";
import StoriesModal from "../components/StoriesModal";

const Welcome = ({
                     storiesIsOpen,
                     showThanksModal,
                     mainContainerRef,
                     userInfo,
                     getStoriesByCohorts,
                     showStories,
                     series,
                     resetStories,
}) => {

    const [maxHeight, setMaxHeight] = useState(0)
    const [maxWidth, setMaxWidth] = useState(0)
    const [cohorts, setCohorts] = useState([])

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            showThanksModal();
        }

        return ()=>{
            resetStories()
            setCohorts([])
        }

    }, []);

    useEffect(() => {
        const calculateHeight = () => {

            if(mainContainerRef.current){
                const calculatedHeight =  window.innerHeight; // - footerHeight;
                const calculatedWidth =   window.innerWidth;
                setMaxHeight(calculatedHeight);
                setMaxWidth(calculatedWidth);
            }
        };

        calculateHeight();

        // Обработчик изменения размеров окна
        const handleResize = () => {
            calculateHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        if(cohorts.length > 0){
            getStoriesByCohorts(cohorts.join())
        }
    }, [cohorts])

    useEffect(() => {
        if(userInfo && cohorts.length === 0){
            setCohorts(userInfo.cohorts)
        }
    }, [userInfo, cohorts]);

    useEffect(() => {
        if(series.length > 0 && userInfo?.isNewStudent){
            const sw = Cookies.get('onboarding')
            if (sw === undefined) {
                showStories()
                Cookies.set('onboarding', true, { expires: 365, path: '' })
            }
        }
    }, [series]);

    return (
        <div className={"relative bg-white w-full"}>
            <Grid/>
            <StoriesModal
                series={series}
                storiesIsOpen={storiesIsOpen}
                maxHeight={maxHeight}
                maxWidth={maxWidth}/>
        </div>
    )
}

Welcome.propTypes = {
    storiesIsOpen: PropTypes.bool,
    showThanksModal: PropTypes.func,
    mainContainerRef: PropTypes.any,
    showStories: PropTypes.func,
    getStoriesByCohorts: PropTypes.func,
    series: PropTypes.array,
    userInfo: PropTypes.object,
    resetStories: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    storiesIsOpen: state.stories.storiesIsOpen,
    series: state.stories.series,
})

const mapDispatchToProps = (dispatch) => ({
    showThanksModal: (message) => dispatch({type: SHOW_THANKS_MODAL, payload: message}),
    showStories: () => dispatch({type: SHOW_STORIES}),
    getStoriesByCohorts: (cohort) => dispatch({type: GET_STORIES_BY_COHORTS, payload: cohort}),
    resetStories: () => dispatch({type: RESET_STORIES}),
})

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)



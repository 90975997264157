import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SprintCollapsed from "../components/SprintCollapsed";
import {GET_SPRINTS_SUMMARIES} from "../../../redux/actions/configurator-actions";
import Spinner from "../../components/Spinner";
import { v4 as uuidv4 } from 'uuid';

const Sprints = ({
                     userUid,
                     getSprintsSummaries,
                     sprintsSummaries,
                     sprintsSummariesLoading,
}) => {

    const [summaries, setSummaries] = useState(null)
    const [refs, setRefs] = useState([])
    const [months, setMonths] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState('all')
    const [currentCollapseId, setCurrentCollapseId] = useState(null)

    useEffect(()=>{
        getSprintsSummaries({
            studentUid: userUid
        })
    }, [])

    useEffect(()=>{
        if(sprintsSummaries){
            const updatedSummaries = sprintsSummaries.sprintsSummaries.map(summary => {
                return {
                    ...summary,
                    id: uuidv4()
                };
            });
            setSummaries(updatedSummaries);
            setMonths(sprintsSummaries.months);
        }
    },[sprintsSummaries])

    useEffect(() => {
        if(summaries){

            const refs = summaries.reduce((acc, value) => {
                acc[value.id] = React.createRef();
                return acc;
            }, {});

            setRefs(refs)
        }

    }, [summaries]);

    function handleSelectFilter(event){

        const value = event.target.value
        let filteredSprints;

        if(value !== 'all'){
             filteredSprints = sprintsSummaries.sprintsSummaries.filter(summary => summary.sprint.month === event.target.value);
        } else {
            filteredSprints = [...sprintsSummaries.sprintsSummaries]
        }

        setSummaries(filteredSprints)
        setSelectedFilter(value)

    }

    return (
        <React.Fragment>
            {sprintsSummariesLoading ? (
                <div className={'flex flex-row flex-1 w-full justify-center items-center'}>
                    <Spinner size={"lg"} />
                </div>
            ) : (
                <div className={"flex flex-col w-full px-4 space-y-4"}>
                    <select
                        className="select select-bordered w-full max-w-xs"
                        value={selectedFilter}
                        onChange={handleSelectFilter}
                    >
                        <option value={"all"} >All months</option>
                        {months && months.map(month=>{
                            return <option key={uuidv4()} value={month}>{month}</option>
                        })}
                    </select>
                    {summaries && summaries.map(summary => {
                        return <SprintCollapsed
                            elementRef={refs[summary.id]}
                            key={uuidv4()}
                            summary={summary}
                            currentCollapseId={currentCollapseId}
                            setCurrentCollapseId={setCurrentCollapseId}
                        />
                    })}
                </div>
            )}

        </React.Fragment>
    )
}

Sprints.propTypes = {
    userUid: PropTypes.string,
    getSprintsSummaries: PropTypes.func,
    sprintsSummaries: PropTypes.object,
    sprintsSummariesLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    sprintsSummaries: state.config.sprintsSummaries,
    sprintsSummariesLoading: state.config.sprintsSummariesLoading,
})

const mapDispatchToProps = (dispatch) => ({
    getSprintsSummaries: (params) => dispatch({ type: GET_SPRINTS_SUMMARIES, payload: params })
})

export default connect(mapStateToProps, mapDispatchToProps)(Sprints)

export const GET_BALANCE_HISTORY = "GET_BALANCE_HISTORY"
export const GET_BALANCE_HISTORY_REQUEST = "GET_BALANCE_HISTORY_REQUEST"
export const GET_BALANCE_HISTORY_SUCCESS = "GET_BALANCE_HISTORY_SUCCESS"
export const GET_BALANCE_HISTORY_ERROR = "GET_BALANCE_HISTORY_ERROR"
export const SET_NEED_UPDATE_BALANCE_HISTORY = "SET_NEED_UPDATE_BALANCE_HISTORY"
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST"
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS"
export const GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR"
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST"
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
export const UPDATE_SUBSCRIPTION_ERROR = "UPDATE_SUBSCRIPTION_ERROR"
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION"
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION"
export const DELETE_SUBSCRIPTION_REQUEST = "DELETE_SUBSCRIPTION_REQUEST"
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS"
export const DELETE_SUBSCRIPTION_ERROR = "DELETE_SUBSCRIPTION_ERROR"
export const DELETE_SUBSCRIPTION_SCHEDULE = "DELETE_SUBSCRIPTION_SCHEDULE"
export const DELETE_SUBSCRIPTION_SCHEDULE_REQUEST = "DELETE_SUBSCRIPTION_SCHEDULE_REQUEST"
export const DELETE_SUBSCRIPTION_SCHEDULE_SUCCESS = "DELETE_SUBSCRIPTION_SCHEDULE_SUCCESS"
export const DELETE_SUBSCRIPTION_SCHEDULE_ERROR = "DELETE_SUBSCRIPTION_SCHEDULE_ERROR"
export const RESET_BILLING_DATA = "RESET_BILLING_DATA"

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import OwnedChallengeDetailsModal from "./OwnedChallengeDetailsModal";
import NotOwnedChallengeDetailsModal from "./NotOwnedChallengeDetailsModal";
import {RESET_SHOWN_CHALLENGE} from "../../redux/actions/challenge-actions";

const ChallengeDetailsModal = ({
                                   showDetailsDialog,
                                   footerHeight,
                                   dialogType
}) => {

    const [maxHeight, setMaxHeight] = useState(0)

    useEffect(() => {
        const calculateHeight = () => {
            const calculatedHeight = window.innerHeight; // - footerHeight;
            setMaxHeight(calculatedHeight);
        };

        calculateHeight();

        const handleResize = () => {
            calculateHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [footerHeight]);

    return (
        <dialog
            className={`flex flex-row justify-center modal modal-top h-screen ${showDetailsDialog ? 'modal-open' : null}`}
        >
            <form method={"dialog"}
                  className={`p-5 modal-box rounded-none relative bg-white w-screen xs:max-w-laptop overflow-hidden`}
                  style={{ maxHeight: maxHeight, height: maxHeight }}
            >
                <>
                    {{
                        'owned': <OwnedChallengeDetailsModal />,
                        'not_owned': <NotOwnedChallengeDetailsModal />
                    }[dialogType]}
                </>
            </form>
        </dialog>
    )
}


ChallengeDetailsModal.propTypes = {
    showDetailsDialog: PropTypes.bool,
    footerHeight: PropTypes.number,
    resetShownChallenge: PropTypes.func,
    dialogType: PropTypes.string
}

const mapStateToProps = (state) => ({
    showDetailsDialog: state.challenge.showDetailsDialog,
    dialogType: state.challenge.dialogType,
    footerHeight: state.common.footerHeight
})

const mapDispatchToProps = (dispatch) => ({
    resetShownChallenge: () => dispatch({ type: RESET_SHOWN_CHALLENGE }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetailsModal)

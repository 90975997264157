import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
// eslint-disable-next-line no-unused-vars
import { Mail, Instagram, CircleCheck, CircleX, Circle } from "lucide-react"
import {useForm} from "react-hook-form";
import {CountryDropdown} from "react-country-region-selector";
import userApi from "../../../redux/api/user-api";
import validator from "validator";

function FirstStep () {

    const [email, setEmail] = useState('')
    const [instagram, setInstagram] = useState('')
    const [currency, setCurrency] = useState('')
    const [country, setCountry] = useState('')
    const [disableNextStep, setDisableNextStep] = useState(false)
    const [instagramCheckIcon, setInstagramCheckIcon] = useState(<Circle />)

    const {
        register,
        // eslint-disable-next-line no-unused-vars
        handleSubmit,
        formState: { errors },
        // eslint-disable-next-line no-unused-vars
        setError,
        // eslint-disable-next-line no-unused-vars
        clearErrors,
    } = useForm({ mode: 'onChange' });

    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false)

    // eslint-disable-next-line no-unused-vars
    const selectCountry = (val) => {
        setCountry(val);
    }

    async function searchByParams(params, type){

        const result = await userApi.searchProfileByParams(params)

        if(result.status === 200) {
            if(result.data.results.length === 0) {
                if(type === 'email') {
                    setEmail(params.email)
                }
                if(type === 'instagram') {
                    setInstagram(params.instagram)
                    setInstagramCheckIcon(<CircleCheck color={"green"} />)
                    errors.instagram && errors.instagram.type === 'validate' && clearErrors("instagram")
                }
            }
            else {
                if(type === 'email') {
                    setEmail(params.email)
                }
                if(type === 'instagram') {
                    setInstagram(params.instagram)
                }
                setInstagramCheckIcon(<CircleX color={"red"}/>)
                setError(type, { type: 'exist', message: `Already exists with another user` });
            }
        } else {
            if(type === 'email') {
                setEmail(params.email)
                setError(type, { type: 'exist', message: `Could not check the ${type}. Try again later` });
            }
            if(type === 'instagram') {
                setInstagram(params.instagram)
                setInstagramCheckIcon(<CircleX color={"red"}/>)
                setError(type, { type: 'exist', message: `Could not check the ${type}. Try again later` });
            }
        }

    }

    useEffect(()=>{
        if((email !== '' && currency !== '' && instagram !== '') && Object.keys(errors).length === 0){
            setDisableNextStep(false)
        } else {
            setDisableNextStep(true)
        }
    },[email, currency, instagram])

    return (
        <div className={"flex flex-col items-center w-full xs:max-w-laptop"}>
            FIRST STEP
            <div className="card w-full bg-base-100 shadow-lg mt-5">
                <div className="card-body">
                    <h2 className="card-title text-base">Create your EIS profile</h2>
                    <p className={"text-xs"}></p>
                    <div className={"space-y-2 mb-4"}>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text text-xs">Мы будем отправлять чеки</span>
                            </div>
                            <label className="input input-bordered input-sm flex items-center gap-2">
                                <Mail />
                                <input
                                    type="text"
                                    className="w-full"
                                    placeholder="Email"
                                    {...register("email", {
                                        required: 'Enter your email',
                                        onChange: async (e) => {
                                            const value = e.target.value
                                            if(validator.isEmail(value)) {
                                                await searchByParams({email: value}, 'email')
                                            }
                                        }
                                    })}
                                />
                            </label>
                            {errors.email &&
                                <label className="label">
                                    {<span className="label-text-alt text-brandRed">{errors.email.message}</span>}
                                </label>
                            }
                        </label>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text text-xs">Чтобы мы могли добавить в группу</span>
                            </div>
                            <label
                                className={`input input-bordered input-sm flex items-center gap-2 ${errors.instagram && 'input-error text-primary prose'}`}>
                                <Instagram />
                                <input
                                    type="text"
                                    defaultValue={instagram}
                                    className={`w-full`}
                                    {...register("instagram", {
                                        required: 'Enter your instagram',
                                        maxLength: {
                                            value: 30,
                                            message: "Too many characters. Max. 30"
                                        },
                                        onChange: async (e) => {
                                            const value = e.target.value
                                            if(value.length > 0) {
                                                await searchByParams({instagram: value}, 'instagram')
                                            } else {
                                                setInstagramCheckIcon(<Circle />)
                                            }
                                        }
                                    })}
                                />
                                {instagramCheckIcon}
                                {/*<CircleCheck color={"green"}/>*/}
                            </label>
                            {errors.instagram &&
                                <label className="label">
                                    {<span className="label-text-alt text-neutral">{errors.instagram.message}</span>}
                                </label>
                            }
                        </label>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text text-xs">Валюта для оплаты</span>
                            </div>
                            <select
                                value={currency}
                                className={`select select-bordered select-sm text-neutral prose`}
                                {...register("currency", {
                                    required: 'Choose a currency',
                                    onChange: (e) => setCurrency(e.target.value)
                                })}
                            >
                                <option disabled value={''}>Select currency</option>
                                <option className={"text-neutral prose"} value={'aud'}>Australian dollar
                                </option>
                                <option className={"text-neutral prose"} value={'rub'}>Russian rouble</option>
                                <option className={"text-neutral prose"} value={'usd'}>US dollar</option>
                                <option className={"text-neutral prose"} value={'eur'}>Euro</option>
                            </select>
                            {errors.currency &&
                                <label className="label">
                                    {<span
                                        className="label-text-alt text-secondary">{errors.currency.message}</span>}
                                </label>
                            }
                        </label>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text text-xs">Это не обязательно, но желательно</span>
                            </div>
                            <CountryDropdown
                                classes={"select select-bordered select-sm text-neutral prose"}
                                value={country || ''}
                                onChange={(val) => selectCountry(val)}
                            />
                        </label>
                    </div>
                    <div className="card-actions justify-end">
                        <button
                            disabled={disableNextStep}
                            className="btn btn-primary"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

FirstStep.propTypes = {}

const mapStateToProps = (state) => ({
    message: state.message.message,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep)
import {
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    SET_CURRENT_PAGE,
    SET_CURRENT_PAGE_REQUESTED,
} from '../actions/common-actions'

function* setCurrentPage({ payload }) {
    yield put({ type: SET_CURRENT_PAGE, payload })
}

export default function* commonSaga() {
    yield takeLatest(SET_CURRENT_PAGE_REQUESTED, setCurrentPage)
}

import { useEffect, useState } from 'react';
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useSelector, useDispatch } from 'react-redux';
import { CAN_INJECT_GTM_SCRIPT, SET_GTM_DATA } from "../redux/actions/common-actions";
import { getSHA256string } from "../helpers/utils";

const useGTM = (userInfo, prevUserInfo) => {
    const dispatch = useDispatch();
    const gtmData = useSelector(state => state.common.gtmData);
    const sendDataToGTM = useGTMDispatch();
    const [canSendGtm, setCanSendGtm] = useState(false);
    const [hasSentGtm, setHasSentGtm] = useState(false);

    useEffect(() => {
        const gtmObject = {};

        if (userInfo && prevUserInfo) {
            const prevProfileEmpty = !prevUserInfo.profile.instagram && !prevUserInfo.profile.email && !prevUserInfo.profile.currency;
            const isProfileSync = userInfo.profile.instagram !== null && userInfo.profile.email !== null && userInfo.profile.currency !== null;

            if (prevProfileEmpty && isProfileSync) {
                gtmObject.is_account_synced = true;
                sendDataToGTM({ user_id: userInfo.uid, event: 'ga4event', event_category: 'account_sync' });
            }

            gtmObject.user_id = userInfo.uid;
            gtmObject.email = getSHA256string(userInfo.profile.email);

            if (userInfo.gtm !== undefined) {
                gtmObject.is_student = userInfo.gtm.isStudent;
                gtmObject.is_customer = userInfo.gtm.isCustomer;
            }

            if (gtmData.injectScript) {
                sendDataToGTM(gtmObject);
            } else {
                gtmObject.login = true;
                dispatch({ type: SET_GTM_DATA, payload: gtmObject });
                setCanSendGtm(true);
            }
        }
    }, [userInfo, prevUserInfo, gtmData.injectScript, sendDataToGTM, dispatch]);

    useEffect(() => {
        if (canSendGtm && !hasSentGtm) {
            if (!gtmData.injectScript) {
                dispatch({ type: CAN_INJECT_GTM_SCRIPT, payload: true });
            }
            setHasSentGtm(true);
        }
    }, [canSendGtm, hasSentGtm, gtmData.injectScript, dispatch]);
};

export default useGTM;
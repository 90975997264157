import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useScroll, motion} from "framer-motion";
import {SHOW_STORIES} from "../../../redux/actions/stories-actions";
import Spinner from "../Spinner";


const Avatar = ({ showStories, loadingSeries, series }) => {

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -100 }
    };

    const { scrollY } = useScroll();
    const [hidden, setHidden] = useState(false);

    useEffect(() => {

        function update() {
            if (scrollY?.current < scrollY?.prev) {
                setHidden(false);
            } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
                setHidden(true);
            }
        }

        const unsubscribeY = scrollY.on("change", update)

        return () => {
            unsubscribeY()
        }
    }, [])

    return (
        <motion.div
            variants={variants}
            animate={hidden ? "hidden" : "visible"}
            transition={{ease: [0.1, 0.25, 0.3, 1], duration: 0.6}}
        >
            {series.length > 0 ? (
                <div
                    className={`h-24 w-24 avatar mt-11 ${loadingSeries ? '' : 'cursor-pointer'} `}
                    onClick={loadingSeries ? null : showStories}
                >
                    <div
                        className="bg-warning rounded-full ring ring-transparent ring-offset-white ring-offset-8 outline outline-green outline-offset-1 outline-4">
                        <div className="text-white rounded-full h-24 w-24 flex flex-row justify-center items-center">
                            {series.length === 0 && loadingSeries ? <Spinner size={"lg"}/> : (
                                <span className="text-3xl">EIS</span>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </motion.div>
    )
}

Avatar.propTypes = {
    loadingSeries: PropTypes.bool,
    showStories: PropTypes.func,
    series: PropTypes.array,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    loadingSeries: state.stories.loadingSeries,
    series: state.stories.series,
    userInfo: state.user.userInfo
})

const mapDispatchToProps = (dispatch) => ({
    showStories: () => dispatch({ type: SHOW_STORIES }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Avatar)



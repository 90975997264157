import React, {useEffect, useState} from 'react'
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {
    CREATE_STUDENT_ANSWERS,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
} from "../../../redux/actions/configurator-actions";

import {FiCheck} from "react-icons/fi";

import Player from "../Player";
import Image from "../Image";
import Loading from "../../components/Loading";
import {SET_PAGE_BACKGROUND} from "../../../redux/actions/common-actions";

const FillTask= ({
                     setHomeworkConfigAssignment,
                     assignment,
                     background,
                     // eslint-disable-next-line no-unused-vars
                     setPageBackground,
                     homeworkType,
                     homeworkId,
                     userInfo,
                     isVideo,
                     isImage,
                     loadingCreateStudentAnswer,
                     withMedia,
                     taskTitle,
                     createStudentAnswer
}) => {

    const [showAssignment, setShowAssignment] = useState(false)

    const componentCleanup = () => {
        setHomeworkConfigAssignment([])
    }

    useEffect(() => {
        if (assignment === null || assignment === undefined) {
            setHomeworkConfigAssignment([]);
        }

        if (background.type !== 'image' && assignment.length > 0) {
            setShowAssignment(true)
            setPageBackground(background.source)
        }

    }, [assignment]);

    useEffect(()=>{
        return () => {
            componentCleanup()
        }
    },[])

    const handleChange = (value, rowIndex, elementIndex) => {
        let results = [...assignment]
        results[rowIndex][elementIndex].studentAnswer = normalizeString(value)
        results[rowIndex][elementIndex].result = value

        if (normalizeString(value) !== normalizeString(results[rowIndex][elementIndex].text)) {
            results[rowIndex][elementIndex].isCorrect = false
        } else if (normalizeString(value) ===normalizeString(results[rowIndex][elementIndex].text)) {
            results[rowIndex][elementIndex].isCorrect = true
        }

        setHomeworkConfigAssignment(results)
    }

    const normalizeString = (string) => {
        return string.toLowerCase().trim().replace(/  +/g, ' ')
    }

    const submitAnswers = (e) => {
        e.preventDefault();

        let all = 0
        let correct = 0
        let tempAssignment = JSON.parse(JSON.stringify(assignment))

        let results = tempAssignment.map(function(row){
            return row.map((element) => {
                if (element.isAnswer){
                    element.result = element.text
                    element.color = 'teal'
                    all += 1
                }
                if (element.isCorrect) {
                    correct += 1
                }
                return element
            })
        })

        let studentAnswers = {
            homework: {uid: homeworkId, type: homeworkType},
            answer: { type: homeworkType, content: results },
            allAssignments: all,
            correctAnswer: correct,
            student: {uid: userInfo.uid}
        }

        createStudentAnswer(studentAnswers)
    }

    return (
        <React.Fragment>
            <div className={[background.type === 'image' && showAssignment ? 'relative' : '',].join('')}>
                {showAssignment ? (
                    <div className={[background.type === 'image' ? 'absolute container' : 'flex-1'].join('')}>
                        <div
                            className="justify-between pt-20 px-4 flex flex-col"
                        >
                            {homeworkType === 'fill' ? (
                                <>
                                    { isVideo ? (
                                        <Player />
                                    ) : null }

                                    { isImage ? (
                                        <Image />
                                    ) : null }
                                </>
                            ) : null}
                        </div>

                        {withMedia ? (<div className="divider w-screen xs:max-w-laptop" />) : null}

                        {taskTitle && (
                            <div className={"px-4 font-bold pb-2"}>{taskTitle}</div>
                        )}

                        <div className={"mt-5 pb-10 flex flex-col text-justify px-4 space-y-4"}>
                            {assignment.map((row, rowIndex) => {
                                if(row.length > 0) {
                                    const useInline = row.findIndex(element => element.isAnswer && (element.style === 'CODE' || element.style === undefined)) > 0
                                    return (
                                        <div
                                            key={`row-${rowIndex}`}
                                            className={`break-words ${useInline ? 'inline-block' : 'flex flex-col space-y-2'}`}
                                            style={{marginTop: 0}}
                                        >
                                            {row.map((element, elementIndex) => {
                                                if(element.style === 'TRUE-FALSE' && element.isAnswer){
                                                    return (
                                                        <select
                                                            className={"w-40 select select-bordered select-sm"}
                                                            key={`element-${rowIndex}-${elementIndex}`}
                                                            name={`element-${rowIndex}-${elementIndex}`}
                                                            defaultValue={' '}
                                                            onChange={event => {
                                                                handleChange(event.target.value, rowIndex, elementIndex)
                                                            }}
                                                        >
                                                            <option value={' '} disabled selected>Pick the answer</option>
                                                            <option value={'true'}>True</option>
                                                            <option value={'false'}>False</option>
                                                        </select>
                                                    )
                                                } else if (element.isAnswer) {
                                                    return <EditText
                                                        key={`element-${rowIndex}-${elementIndex}`}
                                                        name={`element-${rowIndex}-${elementIndex}`}
                                                        type="text"
                                                        style={{
                                                            width: element.result.length > 2 ? element.result.length.toString() + 'ch' : '3ch',
                                                            color: element.result !== '' ? element.color : 'teal',
                                                            opacity: 0.8,
                                                            border: "none",
                                                            paddingLeft: 3,
                                                            paddingRight: 0
                                                        }}
                                                        placeholder={homeworkType === 'put' ? element.correct : 'type here'}
                                                        value={element.result}
                                                        onChange={event => {
                                                            handleChange(event.target.value, rowIndex, elementIndex)
                                                        }}
                                                        inline
                                                    />
                                                } else {
                                                    return useInline ? element.text : <div> {element.text} </div>
                                                }
                                            })}
                                        </div>
                                    )
                                } else {
                                    return <div key={`row-${rowIndex}`} className={"whitespace-pre-line py-1"} />
                                }

                            })}
                        </div>

                        <div className={"flex flex-col items-center pb-3"}>
                            <button
                                disabled={loadingCreateStudentAnswer}
                                className={`py-2 px-4 btn-primary btn btn-md rounded-full btn-wide shadow-brand-button`}
                                onClick={submitAnswers}
                            >
                                {loadingCreateStudentAnswer ? (
                                    <span className="loading loading-spinner loading-xs"></span>
                                ) : (
                                    <FiCheck className="w-6 h-6 stroke-current mr-1"/>
                                )}
                                <span className="font-medium">Submit your answer</span>
                            </button>
                            <p className="text-xs opacity-60 mt-3">You have only one try</p>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-screen bg-brandYellow absolute inset-0">
                        <Loading isFullScreen={true}/>
                    </div>
                )}

                {background.type === 'image' ? (
                    <img
                        className="h-screen object-cover xs:max-w-laptop"
                        alt="background"
                        src={background.source}
                        onLoad={() => {
                            setShowAssignment(true)
                        }}

                    />
                ) : null}

            </div>
        </React.Fragment>
    );
}

FillTask.propTypes = {
    userInfo: PropTypes.object,
    homeworkType: PropTypes.string,
    withMedia: PropTypes.bool,
    isVideo: PropTypes.bool,
    isImage: PropTypes.bool,
    taskTitle: PropTypes.string,
    assignment: PropTypes.array,
    background: PropTypes.object,
    setHomeworkConfigAssignment: PropTypes.func,
    homeworkId: PropTypes.string,
    createStudentAnswer: PropTypes.func,
    studentAnswers: PropTypes.object,
    setPageBackground: PropTypes.func,
    loadingCreateStudentAnswer: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    homeworkType: state.config.type,
    withMedia: state.config.withMedia,
    isVideo: state.config.isVideo,
    isImage: state.config.isImage,
    taskTitle: state.config.taskTitle,
    assignment: state.config.assignment,
    background: state.config.background,
    homeworkId: state.config.homeworkId,
    studentAnswers: state.config.studentAnswers,
    loadingCreateStudentAnswer: state.config.loadingCreateStudentAnswer
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),
    createStudentAnswer: (answer) => dispatch({ type: CREATE_STUDENT_ANSWERS, payload: answer }),
    setPageBackground: (background) => dispatch({ type: SET_PAGE_BACKGROUND, payload: background })
})

export default connect(mapStateToProps, mapDispatchToProps)(FillTask)
import axios from 'axios';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import {store} from '../store';  // Укажите путь к вашему хранилищу
import { REFRESH_TOKEN } from '../actions/auth-actions';  // Укажите тип действия для обновления токена
import {auth} from "../../firebase";

const client = (baseURL) => {

    const instance = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json",
            "Time-Zone": moment.tz.guess(),
        },
        withCredentials: true,
        xsrfHeaderName: "X-CSRFTOKEN",
        xsrfCookieName: "csrftoken"
    });

    instance.interceptors.request.use(
        (config) => {
            const token = store.getState().auth.accessToken;

            if(config.method !== 'get') {
                config.headers["X-CSRFTOKEN"] = Cookies.get('csrftoken')
            }

            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }

            return config;
        },
        (error) => {
            Promise.reject(error)
        }
    );

    instance.interceptors.response.use(
        (res) => res,
        async (error) => {
            const originalConfig = error.config;

            if (error.response) {
                if (error.response.status === 401 && !originalConfig._retry) {
                    handle401Error();
                }

                if (error.response.status === 403 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const idToken = await refreshAuthToken();
                        originalConfig.headers['Authorization'] = 'Bearer ' + idToken;
                        return instance(originalConfig);
                    } catch (error) {
                        handleTokenRefreshError();
                        return Promise.reject(error);
                    }
                }
            }
            return Promise.reject(error);
        }
    );

    const handle401Error = () => {
        // Обработка ошибки 401 (например, перенаправление на страницу выхода)
        window.location.replace('/logout');
    };

    const refreshAuthToken = async () => {
        const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);
        store.dispatch({ type: REFRESH_TOKEN, payload: idToken });
        return idToken;
    };

    const handleTokenRefreshError = () => {
        // Обработка ошибки при обновлении токена (например, перенаправление на страницу выхода)
        window.location.replace('/logout');
    };

    return instance;
}

export default client;
import React from 'react';
import useGTM from '../hooks/useGTM';
import { useSelector } from 'react-redux';
import { usePrevious } from '../hooks/usePrevious';
import PropTypes from "prop-types";

const GTMProvider = ({ children }) => {
    const userInfo = useSelector(state => state.user.userInfo);
    const prevUserInfo = usePrevious(userInfo);
    useGTM(userInfo, prevUserInfo);

    return <>{children}</>;
};

GTMProvider.propTypes = {
    children: PropTypes.node,
}

export default GTMProvider;
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import Cookies from "js-cookie";

import paymentApi from "../redux/api/payment-api";
import { calculatedSum } from "./validation";
import { isProduction } from "./constants";
import UAParser from "ua-parser-js";
import {captureSentryException, captureSentryMessage} from "./sentry";

const usePaymentProcessor = () => {

    const sendDataToGTM = useGTMDispatch();
    const parser = new UAParser()

    const processStripePayment = async (
        {
            setLoading,
            // eslint-disable-next-line no-unused-vars
            stripe,
            weeks,
            isNewCustomer,
            userInfo,
            priceItems,
            returnUrl,
            offer,
        }
    ) => {
        setLoading(true);

        try {
            let response = await paymentApi.createStripeCheckoutSession({
                return_url: returnUrl,
                instagram: userInfo.profile.instagram,
                country: userInfo.profile.country,
                email: userInfo.profile.email,
                currency: userInfo.profile.currency,
                weeks: weeks,
                offer: offer
            });

            if (response.status === 201) {
                const aud_amount = calculatedSum(
                    weeks,
                    isNewCustomer,
                    "aud",
                    priceItems,
                    offer,
                    true
                );

                const gtmData = {
                    user_id: userInfo.uid,
                    event: "ga4event",
                    event_category: "begin_checkout",
                    ecommerce: {
                        payment_type: "by_weeks",
                        items: [
                            {
                                item_name: "English in Stories",
                                price: (aud_amount).toString(),
                                week_number: (weeks).toString(),
                                quantity: "1",
                            },
                        ],
                    },
                }

                if(offer){
                    gtmData.offer = offer.name.split(' ').join('_').toLocaleLowerCase()
                    gtmData.offer_id = offer.id
                } else {
                    gtmData.offer_id = null
                    gtmData.offer = null
                }

                let sentryEventResponse = ''

                if(isProduction) {
                    sendDataToGTM(gtmData)

                    try {
                        sentryEventResponse = captureSentryMessage('send begin checkout event', gtmData)
                    } catch {
                        sentryEventResponse = 'withError'
                    }

                }

                if((isProduction && sentryEventResponse !== '') || !isProduction) {
                    const result = await stripe.redirectToCheckout({
                        sessionId: response.data.sessionId,
                    });

                    if (result.error) {
                        captureSentryMessage(result.error.message)
                    }

                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            captureSentryException(error)
        }
    };

    const processCloudPaymentsPayment = async (
        {
            setLoading,
            weeks,
            isNewCustomer,
            userInfo,
            priceItems,
            currentUser,
            setNeedUpdateBalanceHistory,
            setNeedUpdateUserInfo,
            cloudpaymentsPublicKey,
            defaultPrice,
            offer
        }
    ) => {

        setLoading(true)

        const payments = new window.cp.Widget({
            language: "en-EN",
            email: "",
            applePaySupport: false,
            googlePaySupport: false,
            yandexPaySupport: true,
            tinkoffInstallmentSupport: true,
        });

        const amount = calculatedSum(
            weeks,
            isNewCustomer,
            userInfo.profile.currency,
            priceItems,
            offer
        )

        const aud_amount = calculatedSum(
            weeks,
            isNewCustomer,
            "aud",
            priceItems,
            offer,
            true
        )

        const description = `Английский в историях ${weeks} ${weeks === 1 ? 'неделя' : weeks > 1 && weeks < 5 ? 'недели' : 'недель'}`

        // https://base.garant.ru/74660690/f7ee959fd36b5699076b35abf4f52c5c/
        const receipt = {
            Items: [
                {
                    label: description, //наименование товара
                    price: defaultPrice, //цена
                    quantity: weeks.toFixed(2), //количество
                    amount: amount.toFixed(2), //сумма
                    vat: null, //ставка НДС
                    method: 1, // тег-1214 признак способа расчета - https://base.garant.ru/74660690/f7ee959fd36b5699076b35abf4f52c5c/#block_2032
                    object: 4, // тег-1212 признак предмета расчета - https://base.garant.ru/74660690/f7ee959fd36b5699076b35abf4f52c5c/#block_2033
                    measurementUnit: "шт", //единица измерения
                    AgentSign: 6, //признак агента, тег ОФД 1057, 1222 https://base.garant.ru/74660690/f7ee959fd36b5699076b35abf4f52c5c/#block_7
                    AgentData: { //данные агента, тег офд 1223
                        AgentOperationName: null, // наименование операции банковского платежного агента или банковского платежного субагента, тег ОФД 1044
                        PaymentAgentPhone: null,  // телефон платежного агента, тег ОФД 1073
                        PaymentReceiverOperatorPhone: null, // телефон оператора по приему платежей, тег ОФД 1074
                        TransferOperatorPhone: null, // телефон оператора перевода, тег ОФД 1075
                        TransferOperatorName: null, // наименование оператора перевода, тег ОФД 1026
                        TransferOperatorAddress: null, // адрес оператора перевода, тег ОФД 1005
                        TransferOperatorInn: null // ИНН оператора перевода, тег ОФД 1016
                    },
                    PurveyorData: { //данные поставщика платежного агента,  тег ОФД 1224
                        Name: "IE Anastasia Kariukin", // наименование поставщика, тег ОФД 1225
                        Inn: "000000000000", // ИНН поставщика, тег ОФД 1226
                        Phone: "+79064914593",
                    }
                }
            ],
            calculationPlace: "www.bluebabushka.com", //место осуществления расчёта, по умолчанию берется значение из кассы
            taxationSystem: 1, //система налогообложения; необязательный, если у вас одна система налогообложения
            email: userInfo.profile.email, //e-mail покупателя, если нужно отправить письмо с чеком
            phone: "", //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
            customerInfo: "", // тег-1227 Покупатель - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя (клиента)
            customerInn: "", // тег-1228 ИНН покупателя
            isBso: false, //чек является бланком строгой отчётности
            // AgentSign: 5, //признак агента, тег ОФД 1057 //признак агента, тег ОФД 1057, 1222 https://base.garant.ru/74660690/f7ee959fd36b5699076b35abf4f52c5c/#block_7
            amounts:
                {
                    electronic: amount.toFixed(2), // Сумма оплаты электронными деньгами
                    advancePayment: 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                    credit: 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
                    provision: 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
                }
        }

        const gtmData = {
            user_id: userInfo.uid,
            event: 'ga4event',
            event_category: 'begin_checkout',
            ecommerce: {
                payment_type: 'by_weeks',
                items: [{
                    item_name: 'English in Stories',
                    price: (aud_amount).toString(),
                    week_number: (weeks).toString(),
                    quantity: "1"
                }]
            },
        }

        if(offer){
            gtmData.offer_id = offer.id
            gtmData.offer = offer.name.split(' ').join('_').toLocaleLowerCase()
        } else {
            gtmData.offer_id = null
            gtmData.offer = null
        }

        if(isProduction) {
            sendDataToGTM(gtmData)
            captureSentryMessage('send begin checkout event', gtmData)
        }

        // FOR GTM ANALYTICS
        let _ga_session
        const _ga_cookie = Cookies.get('_ga')
        const ga_project = process.env.REACT_APP_GA_MEASUREMENT_ID.split('-')[1]
        const _ga_session_cookie = Cookies.get(`_ga_${ga_project}`)

        if (_ga_session_cookie) {
            _ga_session = _ga_session_cookie.split('.')
        }

        const client_id = _ga_cookie !== undefined ? `${_ga_cookie.substring(6).toString()}` : `${userInfo.uid}_${currentUser.metadata.createdAt}`
        // FOR GTM ANALYTICS END

        payments.pay('charge',
            { //options
                publicId: cloudpaymentsPublicKey, //id из личного кабинета
                description: description, //назначение
                amount: amount, //сумма
                currency: userInfo.profile.currency.toUpperCase(), //валюта
                email: userInfo.profile.email, //email плательщика (необязательно)
                skin: "mini", //дизайн виджета (необязательно)
                autoClose: 3,
                data: {
                    instagram: userInfo.profile.instagram,
                    isNewCustomer: isNewCustomer,
                    weeks: weeks,
                    firebase_uid: userInfo.uid,
                    email: userInfo.profile.email,
                    gtm: {
                        user_agent: parser.getUA(),
                        client_id: client_id,
                        session_id: _ga_session && _ga_session[2].toString()
                    },
                    offer: offer,
                    CloudPayments: {
                        // Inn: "263209521861",
                        // Type: "Income",
                        CustomerReceipt: receipt,
                    }
                },
            },
            {
                onSuccess: function () { // success
                    setLoading(false)
                    setNeedUpdateBalanceHistory(true)
                    setNeedUpdateUserInfo(true)
                    //действие при успешной оплате
                },
                onFail: function () { // fail
                    setLoading(false)
                    //действие при неуспешной оплате
                },
                onComplete: function () { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    setLoading(false)
                    //например вызов вашей аналитики Facebook Pixel
                }
            }
        )
    };

    return { processCloudPaymentsPayment, processStripePayment };
};

export default usePaymentProcessor;
import React, {useCallback, useEffect, useState} from "react";
import Achievement from "./achievement";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SET_NEED_UPDATE_ACHIEVEMENTS} from "../../../redux/actions/achievement-actions";

const UserAchievementItem = ({
                                 element,
                                 innerIndex,
                                 achievement,
                                 achievementWasShown,
                                 needUpdateAchievements,
                                 setNeedUpdateAchievements,
                                 setSelectedAchievement
}) => {

    const [userAchievement, setUserAchievement] = useState({...element});

    useEffect(() => {
        if (needUpdateAchievements && achievementWasShown === userAchievement.id) {
            const updatedElement = { ...userAchievement };
            updatedElement.wasShown = true;
            setUserAchievement(updatedElement);
            setNeedUpdateAchievements(false);
        }
    }, [achievementWasShown, needUpdateAchievements, userAchievement, setNeedUpdateAchievements]);

    const handleAchievementDetail = useCallback(() => {
        setSelectedAchievement({
            achievement: achievement,
            collection: [userAchievement],
            isCollection: false
        });
    }, [achievement, userAchievement]);

    return (
        <React.Fragment>
            {userAchievement && (
                <div
                    key={userAchievement.id}
                    className={`cursor-pointer mb-4`}
                    onClick={handleAchievementDetail}
                >
                    <div className={"indicator h-full"}>
                        {!userAchievement.wasShown && userAchievement.status !== 'close' && (
                            <span
                                className={`indicator-item badge badge-success text-white 
                                ${userAchievement.wasShown ? 'text-base p-1' : 'text-xs mr-2 mt-1 px-1 pb-0.5'}`}
                            >
                                {!userAchievement.wasShown && 'new'}
                            </span>
                        )}
                        <Achievement
                            index={innerIndex}
                            achievement={userAchievement}
                            isClose={userAchievement.status === 'close'}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>

    );
};

UserAchievementItem.propTypes = {
    element: PropTypes.object,
    innerIndex: PropTypes.number,
    achievement: PropTypes.object,
    achievementWasShown: PropTypes.string,
    needUpdateAchievements: PropTypes.bool,
    setSelectedAchievement: PropTypes.func,
    setNeedUpdateAchievements: PropTypes.func
}

const mapStateToProps = (state) => ({
    achievementWasShown: state.achievement.achievementWasShown,
    needUpdateAchievements: state.achievement.needUpdateAchievements
})

const mapDispatchToProps = (dispatch) => ({
    setNeedUpdateAchievements: (state) => dispatch({ type: SET_NEED_UPDATE_ACHIEVEMENTS, payload: state })
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAchievementItem)
import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import useUserInfo from "../hooks/useUserInfo";

function PrivateRoute({
                          // eslint-disable-next-line react/prop-types
                          children
}) {

    const location = useLocation();
    const pathname = location.pathname || '/'

    const { currentUser } = useAuth()

    const states = {from: pathname}

    useUserInfo();

    if (!currentUser) {
        const queryString = window.location.search;
        return <Navigate to={`/login${queryString}`} state={states}/>
    } else {
        return children
    }
}

PrivateRoute.propTypes = {
    children: PropTypes.node,
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)

import React, {useEffect} from "react";
import {connect} from "react-redux";
import CreateSubscription from "./CreateSubscription";
import Subscription from "./Subscription";
import Spinner from "../components/Spinner";
import PropTypes from "prop-types";
import StripePayment from "./StripePayment";
import {GET_SUBSCRIPTION} from "../../redux/actions/billing-actions";
import StripeElements from "../components/StripeElements";

const StripeProcessing = ({ userSubscription, userInfo, getSubscription, getSubscriptionLoading }) => {

    useEffect(()=>{

        if(userSubscription === null && getSubscriptionLoading === false) {
            const query = {
                currency: userInfo?.profile.currency
            }

            getSubscription({
                uid: userInfo?.uid,
                query: query
            })
        }

    },[userSubscription])

    if(getSubscriptionLoading){
        return <Spinner size={"lg"} />
    } else {
        return (
            <StripeElements>
                <div className={"px-4"}>
                    {userSubscription?.subscription ? (
                        <Subscription/>
                    ) : (
                        <React.Fragment>
                            <CreateSubscription/>
                            <div className="divider">OR</div>
                            <StripePayment/>
                        </React.Fragment>
                    )}
                </div>
            </StripeElements>
        )
    }
}

StripeProcessing.propTypes = {
    userSubscription: PropTypes.object,
    userInfo: PropTypes.object,
    priceItems: PropTypes.object,
    getSubscription: PropTypes.func,
    getSubscriptionLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    userSubscription: state.billing.userSubscription,
    userInfo: state.user.userInfo,
    priceItems: state.preference.priceItems,
    getSubscriptionLoading: state.billing.getSubscriptionLoading,
})

const mapDispatchToProps = (dispatch) => ({
    getSubscription: (query) => dispatch({ type: GET_SUBSCRIPTION, payload: query }),
})

export default connect(mapStateToProps, mapDispatchToProps)(StripeProcessing)

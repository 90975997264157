import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from './redux/store'
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";

import { createRoot } from 'react-dom/client';

import * as Sentry from "@sentry/react";
import {tracePropagationTargets} from "./helpers/constants";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";


if(process.env.REACT_APP_STAGE !== 'local') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_SERVER,
        tunnel: process.env.REACT_APP_ACCOUNT_API_URL + '/sentry/',
        debug: false, // process.env.NODE_ENV !== 'production',
        environment: process.env.REACT_APP_STAGE,
        normalizeDepth: 10,
        // beforeSend: handleSentryBeforeSend,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.extraErrorDataIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.httpClientIntegration(),
            Sentry.replayIntegration(),
            Sentry.replayCanvasIntegration(),
            Sentry.sessionTimingIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: tracePropagationTargets, //tracePropagationTargets,
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        autoSessionTracking: false
    });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<React.Fragment>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>

</React.Fragment>);

serviceWorkerRegistration.register();

reportWebVitals();

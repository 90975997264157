import * as Sentry from "@sentry/react";

export function captureSentryException(error) {
    if(process.env.REACT_APP_STAGE !== 'local') {
        Sentry.captureException(error)
    }
}

export function captureSentryMessage(message, extra= {}) {
    let eventId = ''
    if(process.env.REACT_APP_STAGE !== 'local') {
        eventId = Sentry.captureMessage(message, {extra: {data: JSON.stringify(extra)}})
    }
    return eventId;
}
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from "@redux-saga/core";
import * as Sentry from "@sentry/react";
import {configureStore} from "@reduxjs/toolkit";
import rootReducer from './reducers';
import rootSaga from './sagas';

// Конфигурация персистора для сохранения состояния
const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'common',
        'config',
        'message',
        'notification',
        'user',
        'billing',
        'paymentMethod',
        'preference',
        'challenge',
        'achievement',
        'stories',
        'gift'
    ]
};

// Создание персистированного редюсера
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Создание middleware для работы с redux-saga
const sagaMiddleware = createSagaMiddleware();

// Создание enhancer для интеграции Sentry с Redux
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
        if (action.type === "REFRESH_TOKEN") {
            return {
                ...action,
                accessToken: "filtered",
            };
        }

        if (action.type === "GET_BALANCE_HISTORY_SUCCESS") {
            return {
                ...action,
                balanceHistory: "filtered",
            };
        }

        if (action.type === "GET_CHALLENGES_SUCCESS") {
            return {
                ...action,
                ownedChallenges: "filtered",
                notOwnedChallenges: "filtered",
            };
        }

        if (action.type === "GET_SPRINTS_SUMMARIES") {
            return {
                ...action,
                sprintsSummaries: "filtered",
            };
        }

        if (action.type === "GET_SPRINT_SUCCESS") {
            return {
                ...action,
                currentSprint: "filtered",
            };
        }

        if (action.type === "GET_STUDENT_HOMEWORK_SUCCESS") {
            return {
                ...action,
                background: "filtered",
                assignment: "filtered",
                editorRawData: "filtered",
            };
        }

        if (action.type === "GET_USER_INFO_SUCCESS") {
            return {
                ...action,
                userInfo: {
                    ...action.payload,
                    profile: "filtered",
                    username: "filtered",
                },
            };
        }

        return action;
    },
    stateTransformer: (state) => {
        // Transform the state to remove sensitive information
        return {
            ...state,
            auth: {
                ...state.auth,
                accessToken: "filtered"
            },
            billing: {
                ...state.billing,
                balanceHistory: "filtered",
            },
            achievement: {
                ...state.achievement,
                userAchievements: "filtered",
            },
            challenge: {
                ...state.challenge,
                ownedChallenges: "filtered",
                notOwnedChallenges: "filtered",
            },
            config: {
                ...state.config,
                sprintsSummaries: "filtered",
                currentSprint: "filtered",
                editorRawData: "filtered",
                assignment: "filtered",
                background: "filtered",
            },
            user: {
                ...state.user,
                userInfo: {
                    ...state.user.userInfo,
                    profile: "filtered",
                    username: "filtered",
                },
            }
        };
    },
});

// Создание магазина Redux с конфигурацией
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },

    }).concat(sagaMiddleware),
    enhancers: (getDefaultEnhancers) => process.env.REACT_APP_STAGE !== 'local' ?
        getDefaultEnhancers().concat(sentryReduxEnhancer) : getDefaultEnhancers(),
});

// Создание персистора для сохранения состояния
let persistor = persistStore(store);

// Запуск корневой саги для redux-saga
sagaMiddleware.run(rootSaga);

// Экспорт магазина Redux и персистора
export { store, persistor };
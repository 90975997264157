import React, {useEffect, useState} from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {GET_SPRINT_SUMMARY} from "../../redux/actions/configurator-actions";
import Loading from "../components/Loading";
import DisclosureComponent from "./DisclosureComponent";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {getSummaryDays} from "../../helpers/utils";
import {usePrevious} from "../../hooks/usePrevious";
import {today} from "../../helpers/constants";

const SprintSummary = ({
                           currentSprint,
                           sprintSummary,
                           loadingSprintSummary,
                           getSprintSummary,
                           currentSprintLoading
}) => {

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    const prevCurrentSprintLoading = usePrevious(currentSprintLoading)

    // const [day, setDay] = useState(null)
    // eslint-disable-next-line no-unused-vars
    const [sprintResult, setSprintResult] = useState(70)
    const [sprintDays, setSprintDays] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [])

    useEffect(()=>{

        if(prevCurrentSprintLoading === true && currentSprintLoading === false) {
            if(currentSprint){
                let query = {
                    sprintId: currentSprint.id,
                    studentUid: currentUser.uid
                }
                getSprintSummary(query)
            } else {
                navigate('/welcome')
            }
        } else if (currentSprint && !sprintSummary) {
            let query = {
                sprintId: currentSprint.id,
                studentUid: currentUser.uid
            }
            getSprintSummary(query)
        }

    }, [prevCurrentSprintLoading, currentSprintLoading, currentSprint, currentUser.uid, getSprintSummary, navigate])

    useEffect(()=>{
        if(sprintSummary){

            const days = getSummaryDays(sprintSummary.days)

            setSprintDays(days)

            const sprint = sprintSummary.sprint.allAssignments !== 0 ? sprintSummary.sprint.correctAnswer / sprintSummary.sprint.allAssignments * 100 : 0
            setSprintResult(Number((sprint).toFixed(0)))
        }
    }, [sprintSummary])

    return (
        <>
            {currentSprintLoading || loadingSprintSummary ? (
                <Loading isFullScreen={true}/>
            ) : (
                <div className={"flex flex-col space-y-5 pt-24 px-4"}>
                    {sprintDays.map((day) => {
                        const allAssignments = day.allAssignments
                        const correctAnswer = day.correctAnswer
                        const results = Number((allAssignments !== 0 ? correctAnswer / allAssignments * 100 : 0).toFixed(0))

                        const dayNumber = Number(day.day)

                        if (dayNumber < today.isoWeekday()) {
                            return <DisclosureComponent
                                key={`day-${day.day}`}
                                status={'finished'}
                                day={dayNumber}
                                results={results}
                                homeworks={day.homeworks}
                            />
                        } else if (dayNumber === today.isoWeekday()) {
                            return <DisclosureComponent
                                key={`day-${day.day}`}
                                status={'inProgress'}
                                day={dayNumber}
                                results={results}
                                homeworks={day.homeworks}
                            />
                        } else {
                            return <DisclosureComponent
                                key={`day-${day.day}`}
                                status={'wait'}
                                day={dayNumber}
                                results={0}
                            />
                        }
                    })}
                </div>
            )}
        </>
    )

}

SprintSummary.propTypes = {
    currentUser: PropTypes.object,
    today: PropTypes.any,
    currentSprint: PropTypes.object,
    sprintSummary: PropTypes.object,
    getSprintSummary: PropTypes.func,
    loadingSprintSummary: PropTypes.bool,
    currentSprintLoading: PropTypes.bool
}

const mapStateToProps = (state) => ({
    currentSprint: state.config.currentSprint,
    sprintSummary: state.config.sprintSummary,
    loadingSprintSummary: state.config.loadingSprintSummary,
    currentSprintLoading: state.config.currentSprintLoading
})

const mapDispatchToProps = (dispatch) => ({
    getSprintSummary: (params) => dispatch({type: GET_SPRINT_SUMMARY, payload: params}),
})

export default connect(mapStateToProps, mapDispatchToProps)(SprintSummary)

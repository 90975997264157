import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_USER_INFO } from '../redux/actions/user-actions';
import { useAuth } from '../context/AuthContext';

const useUserInfo = () => {
    const dispatch = useDispatch();
    const { currentUser } = useAuth();
    const needUpdateUserInfo = useSelector(state => state.user.needUpdateUserInfo);

    const userInfo = useSelector(state => state.user.userInfo);

    useEffect(() => {
        if (needUpdateUserInfo && userInfo !== null) {
            console.log('[useUserInfo] -> need update userInfo');
            dispatch({ type: GET_USER_INFO, payload: userInfo.uid });
        }
    }, [needUpdateUserInfo, currentUser, dispatch]);

    useEffect(() => {
        if (currentUser && (!userInfo || userInfo.uid !== currentUser.uid)) {
            console.log('[useUserInfo] -> get userInfo');
            dispatch({ type: GET_USER_INFO, payload: currentUser.uid });
        }
    }, [currentUser, userInfo, dispatch]);
};

export default useUserInfo;
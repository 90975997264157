import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Navigate, useLocation } from "react-router-dom";
import {useAuth} from "../../../context/AuthContext";
import Loading from "../../components/Loading";
import FirstStep from "./FirstStep";

function LoginPage ({
                        message,
                    }) {

    const location = useLocation();
    const from = location.state?.from === undefined || location.state?.from === '/login' ? '/' : location.state?.from

    const pathname = location.pathname === undefined ? '/' : location.pathname

    const { currentUser } = useAuth();

    const navigateTo = (pathname === '/login' && from !== '/' ? from : '/') + `${window.location.search}`

    const [renderComponent, setRenderComponent] = useState(<Loading/>)

    useEffect(()=>{
        if(currentUser) {
            setRenderComponent(<Navigate to={navigateTo}/>)
        } else {
            setRenderComponent(<FirstStep />)
        }

    }, [currentUser, message, navigateTo])

    return (
        <div className={"flex flex-col justify-center items-center w-full xs:max-w-laptop max-h-screen h-screen px-4"}>
            {renderComponent}
        </div>
    )
}

LoginPage.propTypes = {
    message: PropTypes.string,
}

const mapStateToProps = (state) => ({
    message: state.message.message,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE"
export const SET_CURRENT_PAGE_REQUESTED = "SET_CURRENT_PAGE_REQUESTED"
export const SHOW_THANKS_MODAL  = "SHOW_THANKS_MODAL"
export const HIDE_THANKS_MODAL  = "HIDE_THANKS_MODAL"
export const SET_PAGE_BACKGROUND = "SET_PAGE_BACKGROUND"
export const SET_FOOTER_HEIGHT = "SET_FOOTER_HEIGHT"
export const SET_HEADER_ELEMENT_RECT = "SET_HEADER_ELEMENT_RECT"
export const SET_PLAY_TAB = "SET_PLAY_TAB"
export const SET_GTM_DATA = "SET_GTM_DATA"
export const CAN_INJECT_GTM_SCRIPT = "CAN_INJECT_GTM_SCRIPT"
export const SET_NEAREST_ENROLLMENT_SPRINT_DATE = "SET_NEAREST_ENROLLMENT_SPRINT_DATE"

import {
    SHOW_THANKS_MODAL,
    HIDE_THANKS_MODAL,
    SET_PAGE_BACKGROUND,
    SET_FOOTER_HEIGHT,
    SET_PLAY_TAB,
    SET_GTM_DATA,
    CAN_INJECT_GTM_SCRIPT,
    SET_NEAREST_ENROLLMENT_SPRINT_DATE,
    SET_HEADER_ELEMENT_RECT,
} from "../actions/common-actions";

const initialState = {
    thanksModalIsOpen: false,
    pageBackground: '#fff7ef',
    nearestEnrollmentSprintDate: null,
    footerHeight: 0,
    headerElementRect: {},
    playTab: 'not_owned',
    gtmData: {
        id: process.env.REACT_APP_GTM_MEASUREMENT_ID,
        injectScript: false,
        environment: {
            gtm_auth: process.env.REACT_APP_GTM_AUTH,
            gtm_preview: "env-1",
        },
        dataLayer: {}
    },
    canInjectGtmScript: false
}

export default function CommonReducer(state = initialState, {type, payload}) {

    switch(type) {
        case SHOW_THANKS_MODAL:
            return {
                ...state,
                thanksModalIsOpen: true,
            };
        case HIDE_THANKS_MODAL:
            return {
                ...state,
                thanksModalIsOpen: false,
            };

        case SET_PAGE_BACKGROUND:
            return {
                ...state,
                pageBackground: payload,
            };

        case SET_FOOTER_HEIGHT:
            return {
                ...state,
                footerHeight: payload
            }

        case SET_HEADER_ELEMENT_RECT:
            return {
                ...state,
                headerElementRect: payload
            }

        case SET_PLAY_TAB:
            return {
                ...state,
                playTab: payload
            }

        case SET_GTM_DATA:

            // eslint-disable-next-line
            const data = {...state.gtmData.dataLayer}
            Object.assign(data, payload);

            return {
                ...state,
                gtmData: {...state.gtmData, dataLayer: data}
            }

        case CAN_INJECT_GTM_SCRIPT:
            return {
                ...state,
                gtmData: {...state.gtmData, injectScript: payload}
            }

        case SET_NEAREST_ENROLLMENT_SPRINT_DATE:
            return {
                ...state,
                nearestEnrollmentSprintDate: payload
            }

        default:
            return state
    }
}
